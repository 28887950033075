
// THIS IS SUPER WEIRD
// IT said I needed to add in the commented global BigInto to get rid of a warning and...it worked?
// When I'm with Jimoh, def take a look at this

/* global BigInt */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  message,
  Alert,
  Modal,
  warningModal
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {toast} from 'react-toastify';
import { Coinbase_Auth_Token } from "../../const/const";
import CryptoAccountLoginStatus from "../CryptoAccountLoginStatus";
import { getAccountData } from "../../rest/crypto-api/coinbase";
import { useAuth } from "../../contexts/auth-context";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};


// GK 10/22/2023 - Creates the Transaction submission form
// We should add in a network as a variable. So 'solana' or 'ethereum'
const Transaction = () => {
  const storage = localStorage;
  const navigate = useNavigate();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage(); //This comes from ANT Design, we are using this to help display error messages
  const [account, setAccount] = useState([]);
  const [twoFactorRequire, setTwoFactorRequire] = useState(false);
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const coinbaseUserAuthData = useSelector((state) => state.coinbase.coinbaseUserAuth.data?.oauth_meta);
  const cryptoAccount = useSelector(state => state.cryptoAccount.account);
  const auth = useAuth();
  const loginEmail = auth.currentUser?.email;
  const notSignedInCorrectly = ["userinput", "gemini"].includes(cryptoAccount.data?.exchange?.toLowerCase());
  const ethusdc_address = cryptoAccount.data?.ethusdc_address
  const signedInWithMetamask = cryptoAccount.data?.exchange?.toLowerCase() === 'metamask';
  const signedInWithCoinbase = cryptoAccount.data?.exchange?.toLowerCase() === 'coinbase';
  let toastId = null;

  // GK 2023-10-22 - This shows errors via Toast, which we arent using anymore
  const showError = (message) => {
    if(!toast.isActive(toastId)) {
      toastId = toast.error(message, {
        onClose(){
          toastId = null;
        }
      });
    }
  };

  // GK 2023-10-22 - This fetches an account via Axios
  const fetchCoinbaseAccount = async () => {
    const errorMessage = <div>
      You must be logged in with Coinbase to pay someone. Please <Link to="/crypto-login" class="light-blue">login</Link> to proceed!
    </div>;

    try {
      const coinbaseAuthToken = storage.getItem(Coinbase_Auth_Token);
      const response = await getAccountData(coinbaseAuthToken, 'USDC');
      
      if(response) {
        setAccount(response);
      } else {
        setError(errorMessage);
      }

    } catch (error) {
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      setError(errorMessage);
    }
  }

  // GK 2023-10-22 - This fetches the account when the component renders
  useEffect(() => {
    if (signedInWithCoinbase){
      fetchCoinbaseAccount();
    }
  }, []);

  // checks 
  // a) that metamask is installed, 
  // b) that the user is on ethereu mmainnet
  // c) checks the co9nnected wallet's address is equal to ethusdc_address
  const checkMetaMaskAndNetwork = async (ethusdc_address) => {
    // Ensure MetaMask is installed
    if (typeof window.ethereum === "undefined") {
      console.error("MetaMask is not installed.");
      return { success: false, error: "MetaMask is not installed." };
    }
  
    try {
      // Check current network
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
  
      if (chainId !== "0x1") {
        console.log("Not on Ethereum mainnet. Attempting to switch...");
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
          console.log("Switched to Ethereum mainnet.");
        } catch (switchError) {
          if (switchError.code === 4902) {
            return { success: false, error: "Ethereum mainnet is not available in the version of MetaMask installed on your computer." };
          }
          return { success: false, error: "Failed to switch to Ethereum mainnet." };
        }
      }
  
      // Request account access
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
  
      if (accounts.length === 0) {
        return { success: false, error: "No Ethereum account found." };
      }
  
      const walletAddress = accounts[0];
  
      // Check if the wallet address matches ethusdc_address
      if (walletAddress.toLowerCase() === ethusdc_address.toLowerCase()) {
        console.log("Connected to the correct Ethereum address.");
        return { success: true, address: walletAddress };
      } else {
        console.error("Connected wallet does not match ethusdc_address.");
        return { success: false, error: "Stored wallet address does not match ethusdc_address." }; 
        // UPDATE ERROR ABOVE
      }
  
    } catch (error) {
      console.error("Error checking MetaMask and network:", error);
      return { success: false, error: "An error occurred while checking MetaMask and network." };
    }
  };

  const metamaskSendUSDC = async (toAddress, fromAddress, amount) => {
    // Use Ant Design message to show warning
    const warningKey = 'transaction-warning';
    const warningMessage = message.loading({
      content: 'Transaction in progress. Do not navigate away from Punkypay.com - doing so may result in errors.',
      key: warningKey,
      duration: 0, // Don't auto-dismiss
      style: {
        marginTop: '50px', // To ensure visibility
      }
    });
  
    // Create modal for back button attempts
    const warningModal = Modal.warning({
      title: 'Transaction in Progress',
      content: 'Please do not navigate away while your transaction is being processed. Doing so will cause Punkypay to not record this transaction.',
      maskClosable: false,
      closable: false,
      visible: false, // Start hidden
      footer: [
        <button key="stay" type="primary" onClick={() => warningModal.destroy()}>
          Stay on Page
        </button>
      ]
    });
  
    // Add event listener for beforeunload (closing tab/browser)
    const beforeUnloadListener = (e) => {
      e.preventDefault();
      // Standard way of showing a confirmation message in most browsers
      e.returnValue = 'Transaction in progress! If you leave now, your transaction may still process but you won\'t be able to track it here.';
      return e.returnValue;
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
  
    // Also prevent going back with browser history
    const preventNavigation = (e) => {
      e.preventDefault();
      warningModal.update({ visible: true }); // Show modal when user tries to go back
      // Stay on current page
      return false;
    };
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', preventNavigation);
  
    try {
      // Ensure MetaMask is installed
      if (typeof window.ethereum === "undefined") {
        throw new Error("MetaMask is not installed.");
      }
  
      // Request account access and get the current account
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await window.ethereum.request({ method: "eth_accounts" });
      const walletAddress = accounts[0];
  
      if (walletAddress !== fromAddress) {
        throw new Error("The wallet saved in Punkypay does not match this Metamask account's wallet. Please sign in again.");
      }
  
      // ✅ USDC Contract Address on Ethereum Mainnet
      const USDC_CONTRACT_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eb48";
  
      // ✅ USDC Transfer ABI
      const usdcTransferABI = [
        {
          "constant": false,
          "inputs": [
            { "name": "to", "type": "address" },
            { "name": "value", "type": "uint256" }
          ],
          "name": "transfer",
          "outputs": [{ "name": "", "type": "bool" }],
          "payable": false,
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ];
  
      // ✅ Calculate amount in smallest unit (USDC has 6 decimals)
      const decimals = 6;
      const usdcAmount = BigInt(amount * 10 ** decimals).toString(16);
  
      // ✅ Encode the transaction data for the `transfer` function
      const methodId = "a9059cbb"; // Keccak-256 of "transfer(address,uint256)" first 4 bytes
      const paddedToAddress = toAddress.replace("0x", "").padStart(64, "0");
      const paddedAmount = usdcAmount.padStart(64, "0");
      const data = `0x${methodId}${paddedToAddress}${paddedAmount}`;
  
      // ✅ Estimate Gas with buffer (For USDC contract call)
      const rawGasEstimate = await window.ethereum.request({
        method: "eth_estimateGas",
        params: [{
          from: fromAddress,
          to: USDC_CONTRACT_ADDRESS,
          data: data,
        }]
      });
  
      // Add buffer to gas estimate (20%)
      const gasBuffer = 1.2;
      const gasEstimateInt = parseInt(rawGasEstimate, 16);
      const bufferedGasEstimate = Math.floor(gasEstimateInt * gasBuffer);
      const gasEstimate = '0x' + bufferedGasEstimate.toString(16);
  
      // ✅ Get Current Gas Price with buffer
      let gasPrice = await window.ethereum.request({ method: "eth_gasPrice" });
  
      // Add buffer to gas price (20%)
      const gasPriceBuffer = 1.2;
      const gasPriceInt = parseInt(gasPrice, 16);
      const bufferedGasPrice = Math.floor(gasPriceInt * gasPriceBuffer);
      const finalGasPrice = '0x' + bufferedGasPrice.toString(16);
  
      // ✅ Check ETH balance (for gas fees)
      const balance = await window.ethereum.request({
        method: "eth_getBalance",
        params: [walletAddress, "latest"]
      });
  
      const balanceInEth = BigInt(balance);
      const gasCost = BigInt(parseInt(gasEstimate, 16)) * BigInt(parseInt(finalGasPrice, 16));
  
      // Convert Wei to ETH
      const weiToEth = (wei) => Number(wei) / 1e18;
      const ethCost = weiToEth(gasCost);
      const ethBalance = weiToEth(balanceInEth);
  
      if (balanceInEth < gasCost) {
        throw new Error(`Not enough ETH to cover gas fees. 
          Required: ${ethCost.toFixed(6)} ETH. 
          Your Balance: ${ethBalance.toFixed(6)} ETH.`);
      }
  
      // ✅ Send Transaction (to USDC contract, NOT recipient)
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [{
          from: fromAddress,
          to: USDC_CONTRACT_ADDRESS,
          data: data,
          gas: gasEstimate,
          gasPrice: finalGasPrice
        }]
      });
  
      console.log("Transaction sent! Tx Hash:", txHash);
  
      const fetchEthToUsdcRate = async () => {
        try {
          const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
          const data = await response.json();
          return data.ethereum.usd;  // Returns the ETH to USD rate
        } catch (error) {
          console.error("Error fetching ETH to USDC rate:", error);
          return 2000;  // Default rate if the API call fails
        }
      };
  
      const convertEthToUsdc = async (ethCost) => {
        const ethToUsdcRate = await fetchEthToUsdcRate();
        const usdcCost = ethCost * ethToUsdcRate;
        console.log("Gas cost in USDC:", usdcCost);
  
        return usdcCost;
      };
  
      // Inside your metamaskSendUSDC function
      const usdcCost = await convertEthToUsdc(ethCost);  // Convert gas cost to USDC
      console.log("Gas cost in USDC:", usdcCost);
  
      // Clean up warning elements and event listeners after transaction is sent
      message.success({ content: 'Transaction submitted successfully!', key: warningKey, duration: 2 });
      warningModal.destroy();
      window.removeEventListener('beforeunload', beforeUnloadListener);
      window.removeEventListener('popstate', preventNavigation);
  
      // console.log(txHash);  // returns 0x0e3e071d7494b8d62ff3e093eb76831ca2c3d575328695fbbc7b7a13ae884497
      // console.log(gasEstimate); // returns the gas estimate with buffer
      // console.log(finalGasPrice); // returns the gas price with buffer
      // console.log(ethCost);  // returns gas cost in ETH
      // console.log(usdcCost); // returns gas cost in USDC
  
      // Return both the transaction hash and the gas info as an object
      return {
        txHash,
        gasInfo: {
          gasEstimate: gasEstimate,
          gasPrice: finalGasPrice,
          estimatedCostETH: ethCost,
          estimatedCostUSDC: usdcCost
        }
      };
  
    } catch (error) {
      // Clean up warning elements and event listeners on error
      message.error({ content: error.message, key: warningKey, duration: 2 });
      warningModal.destroy();
      window.removeEventListener('beforeunload', beforeUnloadListener);
      window.removeEventListener('popstate', preventNavigation);
  
      setLoading(false); // Stop loading on error
  
      if (error.code === 4001) {
        setError("Transaction rejected by the user.");
        console.error("User denied transaction signature.");
        // Return error information
        return {
          error: true,
          message: "Transaction rejected by the user."
        };
      } else {
        setError(error.message);
        console.error("Error in SendUSDC:", error.message);
        // Return error information
        return {
          error: true,
          message: error.message
        };
      }
    }
  };
  
  

  // something like "if user is signed in with metamask, then run this:"
  useEffect(() => {
    if (signedInWithMetamask){
      checkMetaMaskAndNetwork(ethusdc_address).then(result => {
        if (result.success) {
          console.log("All checks passed. Wallet address:", result.address);
        } else {
          console.error("Check failed:", result.error);
          setError(result.error);
        }
      });
    }
  }, []);
  
  

  // GK 2023-10-22 - When the submit button is pressed, the send-crypto api call gets run with the supplied requestData
  const onFinish = async (value) => {
    if(!account) {
      setError('Can\'t fetch the user account. Please login again to perform this operation.')
      return;
    }
    console.log(loginEmail);
    if(!loginEmail) { 
      setError('Can\'t fetch the user login email. Please login again to perform this operation.');
      return;
    }
    
    // If exchange = coinbase, do something, else if metamask, do something else
    if (signedInWithCoinbase) {
      setLoading(true);
      // I SHOULD GET THIS WHOLE TRY BLOCK TO RUN ASYNCHRONOUSLY
      try {
        
        const requestData = {
          accountId: account.id,
          accessToken: storage.getItem(Coinbase_Auth_Token),
          to: value.recipient.toLowerCase().trim(),
          from: loginEmail,
          amount: value.amount,
          currency: "USDC",
          network_name: 'ethereum', // This should be different based on the variable we put in at the top of this function
          shortMemo: value.shortMemo,
          notes: value.notes,
          twoFactorToken: twoFactorRequire ? value?.twoFactorToken : null,
        };
        console.log("requestData =>>" , requestData)
        const response = await axios.post('/api/transactions/send-crypto-coinbase', requestData);
        console.log("send crypto response =>>", response)
        if(response) {
          console.log('Transaction Created successfully =>', response.data);
          navigate("/transactions");
          setLoading(false);
        }
      } catch (error) {
        console.log('Transaction Create: Error =>', error);
        // If the error response indicates an expired token, sign out the user
        if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
          auth.signOut();
        }
        const errorData = error.response.data;
        setLoading(false);
        if(errorData.errorCode) { // This means the error is our customized error from the server          
          setError(errorData.message);
          return;
        }
        console.log('Transaction Create: 2FA Auth required =>', errorData);
        let errorString = "";
        // GK 2023-10-22 - the errorData is an array coming from coinbase, we are looking for the "id" key
        errorData.errors?.forEach((err) => {
          if (err?.id === "two_factor_required") {
            setTwoFactorRequire(true);
            errorString += 'Please enter two-factor authentication token for Coinbase. You will recieve the token on an authenticator app (like Google Authy), text message, email, or security prompt.'
          } else {
            errorString += err?.message + "\n";
          }
        });
        setError(errorString);
      }
    } 

    if (signedInWithMetamask) {
      console.log("Metamask actions coming soon.")
      setLoading(true);

      try {

        const toAndFromData = {
          // accountId: account.id, // I dont think I need this for metamask? or I should use the crypto address? I think this is supposed to be the ethusdc_account identifier?
          // accessToken: storage.getItem(Coinbase_Auth_Token), //I won't need this
          to: value.recipient.toLowerCase().trim(), 
          from: loginEmail, 
          amount: value.amount,
          currency: "USDC",
          network_name: 'ethereum', // This should be different based on the variable we put in at the top of this function
          shortMemo: value.shortMemo,
          notes: value.notes,
          // twoFactorToken: twoFactorRequire ? value?.twoFactorToken : null, // do not need this
        };
        console.log("requestData =>>" , toAndFromData)

        // I decided to run this command, rather than running the sendUSDC() metamask function directly, 
        // becasue I think the code will be more readable when I put it in the API function call
        const toAndFrom = await axios.post('/api/transactions/get-ethusdc-sender-receiver', toAndFromData);

        const responseSendUSDC = await metamaskSendUSDC(toAndFrom.data.sent_to, toAndFrom.data.sent_from, toAndFromData.amount);
        
        console.log(responseSendUSDC.txHash);
        console.log(responseSendUSDC.gasInfo.estimatedCostUSDC);
        const sendCryptoData = {
          // accountId: account.id, // I dont think I need this for metamask? or I should use the crypto address? I think this is supposed to be the ethusdc_account identifier?
          // accessToken: storage.getItem(Coinbase_Auth_Token), //I won't need this
          to: value.recipient.toLowerCase().trim(), 
          from: loginEmail, 
          amount: value.amount,
          currency: "USDC",
          network_name: 'ethereum', // This should be different based on the variable we put in at the top of this function
          txn_id: responseSendUSDC.txHash,
          gas_cost: responseSendUSDC.gasInfo.estimatedCostUSDC.toString(),
          shortMemo: value.shortMemo,
          notes: value.notes,
          // twoFactorToken: twoFactorRequire ? value?.twoFactorToken : null, // do not need this
        };

        const responseSaveInDatabase = await axios.post('/api/transactions/send-crypto-metamask', sendCryptoData);
        console.log(responseSaveInDatabase)

        if(responseSaveInDatabase) {
          console.log('Transaction Created successfully =>', responseSaveInDatabase.data);
          navigate("/transactions");
          setLoading(false);
        }
      } catch (error) {
        console.log('Transaction Create: Error =>', error);
                // If the error response indicates an expired token, sign out the user
        if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
          auth.signOut();
        }
        const errorData = error.response.data;
        setLoading(false);
        if(errorData.errorCode) { // This means the error is our customized error from the server
          setError(errorData.message);
          return;
        }

        // 2025-02-15 GK
        // I think the below shouldn't exist because we're on Metamask now, and we shouldn't need the below.
        // Double check the error from metmask isn't in an array format...
        console.log('Transaction Create: 2FA Auth required =>', errorData);
        let errorString = "";
        // GK 2023-10-22 - the errorData is an array coming from coinbase, we are looking for the "id" key
        errorData.errors?.forEach((err) => {
          if (err?.id === "two_factor_required") {
            setTwoFactorRequire(true);
            errorString += 'Please enter two-factor authentication token for Coinbase. You will recieve the token on an authenticator app (like Google Authy), text message, email, or security prompt.'
          } else {
            errorString += err?.message + "\n";
          }
        });
        // 2025-02-14 GK Theoretically, I shouldn't need the above

        setError(errorString);
      }
    }
    // Note: if you arent signed in with Coinbase or Metamask, another error message will catch this. 
    // Please look at code below. 
    // I should probably remove the ability to see the Pay Now form, but that is for another time. 
  }

  // NEVER ENDED UP NEEDING THIS TO CLEAN TRANSACTIONS IN METAMASK, BUT IT WORKED
  // const isMetaMaskBusy = async () => {
  //   const pendingRequests = await window.ethereum.request({
  //     method: 'wallet_getPermissions',
  //     params: [{ eth_accounts: {} }]
  //   });
  //   console.log("Pending Requests:", pendingRequests); // Log the pending requests
  
  //   return pendingRequests.length > 0;
  // };
  
  // // Before sending a new transaction
  // if (isMetaMaskBusy()) {
  //   console.warn("MetaMask already has a pending request. Please wait.");
  //   // "Ah, huge bummer. Metamask had an issue. You have to backup your seed phrase from metamask, and then reset it. Look at this guide to help."
  //   return;
  // }

  // THIS CLEANED TRANSACTIONS IN METAMASK ... WHAT IF THERE WAS A TRANSACTION I WANTED TO GO THROUGH
  // HOW DID THE GAS NOT BE ENOUGH THAT ONE TIME...??

  // const cancelPendingTransaction = async () => {
  //   try {
  //     // 1. Ensure MetaMask is available
  //     if (typeof window.ethereum === 'undefined') {
  //       console.error("MetaMask is not installed!");
  //       return;
  //     }
  
  //     // 2. Get the user's accounts
  //     const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  //     const account = accounts[0]; // Get the first account
  
  //     if (!account) {
  //       console.error("No account found in MetaMask.");
  //       return;
  //     }
  
  //     // 3. Get the current nonce for the user's address
  //     const nonce = await window.ethereum.request({
  //       method: 'eth_getTransactionCount',
  //       params: [account, 'latest'], // Get nonce for the latest transactions
  //     });
  
  //     console.log("Current Nonce:", nonce);
  
  //     // 4. Get the current gas price
  //     const gasPrice = await window.ethereum.request({
  //       method: 'eth_gasPrice', // Fetch current gas price
  //     });
  
  //     const newGasPrice = (parseInt(gasPrice, 16) * 2).toString(16); // Double the current gas price
  
  //     // 5. Prepare a new transaction using the same nonce but higher gas price
  //     const newTransaction = {
  //       from: account,
  //       to: account, // Send it to your own address to avoid changing the state
  //       value: '0x0', // No value transfer
  //       gasPrice: newGasPrice, // Use the new higher gas price
  //       nonce: nonce, // Same nonce as the pending transaction
  //     };
  
  //     // 6. Send the new transaction
  //     const txHash = await window.ethereum.request({
  //       method: 'eth_sendTransaction',
  //       params: [newTransaction],
  //     });
  
  //     console.log("Cancelled pending transaction by sending a new one. TX Hash:", txHash);
  //   } catch (error) {
  //     console.error("Error cancelling transaction:", error);
  //   }
  // };
  
  // // Run the method
  // cancelPendingTransaction();

  // GK 2023-10-22 - below is the HTML for the transaction component
  return (
    <div style={{ marginTop: "10px" }}>
      <Title className="text-center" level={3}>Pay Now</Title>
      <div className="text-center" style={{marginBottom: '10px'}}>
        <CryptoAccountLoginStatus/>
      </div>
      {/* Below does not work anymore because Coinbase broke the User Auth endpoint */}
      {/* {
        coinbaseUserAuthData && !notSignedInCorrectly && (
          <div className="text-center" style={{marginBottom: '10px'}}>
            <Text
              style={{marginBottom: '5px'}} 
              type="warning"
            >
              You can only send {
              coinbaseUserAuthData.send_limit_amount
              } {
              coinbaseUserAuthData.send_limit_currency
              } per {
                coinbaseUserAuthData.send_limit_period
              } based on your coinbase settings
            </Text>
          </div>
        )
      } */}
      
      <Form
        labelCol={{
          xs: {span: 24},
          md: {span: 8}
        }}
        wrapperCol={{
          xs: {span: 24},
          md: {span: 8},
        }}
        form={form}
        name="transaction"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // style={{ maxWidth: 800, margin: "auto" }}
      >
      
        <Form.Item
              name={"recipient"}
              label={"Recipient's Punkypay Email"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <Input placeholder={"Recipient"} maxLength={225} disabled={twoFactorRequire}/>
        </Form.Item>
        <Form.Item
              name={"amount"}
              label={"Amount (USDC)"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <InputNumber
              placeholder={"Amount"}
              style={{ width: "100%" }}
              min={0}
              disabled={twoFactorRequire}
            />
        </Form.Item>
        <Form.Item
              name={"shortMemo"}
              label={"Short Memo"}
              rules={[{ required: true }]}
              className="form-item"
            >
            <Input placeholder={"Short Memo"} maxLength={225} disabled={twoFactorRequire}/>
        </Form.Item>
        <Form.Item
              name={"notes"}
              label={"Notes"}
              rules={[{ required: false }]}
              className="form-item"
            >
            <TextArea disabled={twoFactorRequire} />
        </Form.Item>
        {twoFactorRequire && (
          <Form.Item
            name={"twoFactorToken"}
            label={"Two Factor Code"}
            rules={[{ required: true }]}
            className="form-item"
          >
            <Input placeholder={"Enter Two Factor Code"} maxLength={225} />
          </Form.Item>
        )}
        <Form.Item
          className="form-item form-item__submit"
          wrapperCol={{
            md: {
              offset: 8,
              span: 16,
            },
            xs: {
              span: 24,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={loading || notSignedInCorrectly}>
              Submit
          </Button>
        </Form.Item>
        {/* <Row gutter={24}>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            
          </Col>
        </Row> */}
        
        {/* <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" disabled={loading || notSignedInCorrectly}>
              Submit
            </Button>
          </Col>
        </Row> */}
        {
              error && (
                <Row>
                  <Col
                    xs={{span: 24}}
                    md={{span: 16, offset: 4}}
                    style={{marginTop: "10px"}}
                  >
                    <Alert message={error} type="error" />
                  </Col>
                </Row>
              )
        }
        {
              notSignedInCorrectly && (
                <Row>
                  <Col
                    xs={{span: 24}}
                    md={{span: 16, offset: 4}}
                    style={{marginTop: "10px"}}
                  >
                    <Alert message="Please sign into Coinbase or Metamask to make a transaction, you cannot make a transaction when you are logged in with the Punkypay Mailroom or Gemini." type="warning" />
                  </Col>
                </Row>
              )
        }
      </Form>

    </div>
  );
};

export default Transaction;
