import * as React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import App from '../App';
import TransactionList from "../Components/Transaction/TransactionList";
import Transaction from "../Components/Transaction/Transaction";
import TransactionDetails from "../Components/Transaction/TransactionDetails";
import AuthComponent from "../Components/Common/AuthComponent";
import CryptoLogin from "../Components/CryptoLogin";
import ManualAddress from "../Components/ManualAddress";
import { AuthProvider } from '../contexts/auth-context';
import { ErrorScreen } from '../contexts/error-screen-context';
import RequireAuth from '../Components/Common/RequireAuth';
import AuthActionsLandingPage from "../Components/Common/AuthActionsLandingPage";
import AuthActions from "../Components/Common/AuthActions";
import ConfirmEmail from '../Components/Common/ConfirmEmail';
import PasswordReset from "../Components/Common/PasswordReset";
import ConfirmPasswordReset from "../Components/Common/ConfirmPasswordReset";
import Home from '../Components/Home';
import Roadmap from '../Components/Roadmap';
import UseCases from '../Components/UseCases';
import Podcast from '../Components/Podcast';
import UnderstandTXNDetails from '../Components/Transaction/UnderstandTXNDetails';
import HowMuchPunkypaySavesYou from "../Components/HowMuchPunkypaySavesYou";
import CostBreakdown from '../Components/CostBreakdown';
import Support from '../Components/Support';
import TermsOfUse from "../Components/TermsOfUse";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import MetaMaskConnection from "../Components/MetaMaskConnection";


export const PUBLIC_URLS = [
  '/',
  '/login',
   '/register',
   '/confirm-email',
   '/confirm-password-reset',
   '/password-reset',
   '/how-much-punkypay-saves-you',
   '/terms-of-use',
   '/privacy-policy',
   '/roadmap',
]


// GK 2023-10-22 - this is the router for navigating to different pages
const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider><App/></AuthProvider>,
    errorElement: <ErrorScreen />,
    children: [
      // START: public routes - routes that don't require authentication to access
        {
            index: true,
            element: <Home/>,
            errorElement: <ErrorScreen />,
        },
        {
          path: 'login',
          element: <AuthComponent title="Login" />,
          errorElement: <ErrorScreen />,
        },
        {
            path: 'register',
            element: <AuthComponent title="Register" />,
            errorElement: <ErrorScreen />,
        },
        {
          path: '/confirm-email',
          element: <ConfirmEmail />,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/confirm-password-reset',
          element: <ConfirmPasswordReset/>,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/password-reset',
          element: <PasswordReset/>,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/roadmap',
          element: <Roadmap />,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/podcast',
          element: <Podcast />,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/use-cases',
          element: <UseCases />,
          errorElement: <ErrorScreen />,
        },
        { 
          path: '/how-much-punkypay-saves-you',
          element: <HowMuchPunkypaySavesYou/>,
          errorElement: <ErrorScreen />,
        },
        {
          path: '/cost-breakdown',
          element: <CostBreakdown/>,
          errorElement: <ErrorScreen />,
        },
        { 
          path: '/terms-of-use',
          element: <TermsOfUse/>,
          errorElement: <ErrorScreen />,
        },
        { 
          path: '/privacy-policy',
          element: <PrivacyPolicy/>,
          errorElement: <ErrorScreen />,
        },
        // END: public routes
        // START: Protected routes - routes that require authentication to access
        {
            path: 'crypto-login',
            element: <RequireAuth><CryptoLogin/></RequireAuth>,
            errorElement: <ErrorScreen />,
        },
        {
            path: 'transactions',
            element:  <RequireAuth><TransactionList /></RequireAuth>,
            errorElement: <ErrorScreen />,
        },
        {
            path: 'transactions/:transactionId',
            element:  <RequireAuth><TransactionDetails/></RequireAuth>, 
            errorElement: <ErrorScreen />,
        },
        {
            path: 'transaction',
            element:  <RequireAuth><Transaction/></RequireAuth>,
            errorElement: <ErrorScreen />,
        },
        {
            path: 'manual-address',
            element:  <RequireAuth><ManualAddress/></RequireAuth>,
            errorElement: <ErrorScreen />,
        },
        {
            path: '/metamask',
            element:  <RequireAuth><MetaMaskConnection/></RequireAuth>,
            errorElement: <ErrorScreen />,
        },
        {
          path: 'support',
          element:  <RequireAuth><Support /></RequireAuth>,
          errorElement: <ErrorScreen />,
        },
        { 
          path: '/understand-txn-details',
          element: <RequireAuth><UnderstandTXNDetails/></RequireAuth>,
          errorElement: <ErrorScreen />,
        }, 
    ],
    
  },
  // Uncomment below if you want to use Online version of firebase
  { // Exclusive firebase route for handling firebase related actions
    path: 'auth/action',
    element: <AuthActions><AuthActionsLandingPage/></AuthActions>
  },
  // START: Route Below is strictly for use by firebase local emulator
  // { // Exclusive local firebase Emulator route for handling firebase related actions
  //   path: '/emulator/action',
  //   element: <AuthActions><AuthActionsLandingPage/></AuthActions>
  // }
  // END: Route Above is strictly for use by firebase local emulator
]);

export default router;
