import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Descriptions } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { Coinbase_Auth_Token } from "../../const/const";
import { useAuth } from '../../contexts/auth-context';
import { ErrorScreen } from '../../contexts/error-screen-context';
import { toCamelCase } from '../../helpers/helpers.js';
import './styles/TransactionDetails.css';

// GK 2023-10-22 - creates the TransactionDetails page, which gets rendered after clicking on
// a transaction in the TransactionList component 
const TransactionDetails = (props) => {
  const storage = localStorage;
  const { formData = {} } = props;
  const [transaction, setTransaction] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const [inProgress, setInProgress] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const auth = useAuth();
  const loginEmail = auth.currentUser?.email;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  // GK 2023-10-22 - gets a specific transaction
  const getTransaction = async (transactionId, email) => {
    try {
      const response = await axios.post(`/api/transactions/${transactionId}`, {
        loginEmail: email,
        accountId: null, //result[0]?.id,
        accessToken: storage.getItem(Coinbase_Auth_Token),
      });
  
      console.log('Mongo Transaction', response.data);
      setTransaction(response.data);
      setInProgress(false);

    } catch (error) {
      console.log('Transaction Error ->', error);
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      setError('Something went wrong. Try again');
      setInProgress(false);
    }
  }

  // GK 2023-10-22 - updates transaction details
  const updateTransaction = async (transactionId, transactionData) => {
    setIsUpdating(true);
    try {
      const response = await axios.patch(`/api/transactions/${transactionId}`, transactionData);
  
      console.log('Mongo Transaction Update ->', response.data);
      setTransaction(response.data);
      setIsUpdating(false);
      // TODO: show some alert to notify the user that an update has happened
      alert('Transaction successfully updated'); // To be removed

    } catch (error) {
      console.log('Transaction Update Error ->', error);
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      setError('Something went wrong! Try again');
      setIsUpdating(false);
    }
  }

  // GK 2023-10-22 - runs the getTransaction function when you click on the transaction from the TransactionList
  // this is important to update the transaction data (eg. the "Status" from coibnase) when you click on it
  useEffect(() => {
    if(!transactionId) {
      navigate(-1);
      return;
    }
    
    if(!loginEmail) {
      return;
    }

    getTransaction(transactionId, loginEmail);

  }, [transactionId, loginEmail]);


  if (error) {
    return <ErrorScreen 
      title="Transaction Error"
      message={error}
      // onRetry={() => {
      //   setError(null);  // Clear the error
      //   fetchTransaction();  // Try again
      // }}
    />;
  }

  // GK 2023-10-22 - when you hit submit, you update the transaction
  const onFinish = (value) => {
    console.log("onFinish:", value);
    updateTransaction(transactionId, value);
  };
  
  if(inProgress) {
    return <div>Loading...</div>
  }

  const isSenderNotesAvailable = transaction?.sender_notes?.trim();
  const isRecipientNotesAvailable = transaction?.recipient_notes?.trim();

  const displayExchangeName = input => {
      if (input.toUpperCase() === "COINBASE") {
          return "Coinbase";
      } else if (input.toUpperCase() === "USERINPUT") {
          return "Punkypay Mailroom";
      } else if (input.toUpperCase() === "GEMINI") {
        return "Gemini";
      } else if (input.toUpperCase() === "METAMASK") {
        return "Metamask";
      } else {
          // Handle other cases if needed
          return "Unknown Exchange";
      }
  }
  return (
    <div>
      <Form
        layout="vertical"
        labelCol={{
            xs: {span: 24},
            md: {span: 12},
            lg: {span: 8}
        }}
        wrapperCol={{
            xs: {span: 24},
            md: {span: 12},
            lg: {span: 8}
        }}
        name="leave_action_form"
        onFinish={onFinish}
        initialValues={
          {senderNotes: transaction.sender_notes, recipientNotes: transaction.recipient_notes}
        }
      >
        <p> For more information about this page, <Link class="light-blue" to="/understand-txn-details"> click here</Link></p>
        <p> <b> Transaction Memo: </b> {transaction.memo_at_txn}</p>

          {/* IF the sender is currently logged in, show the notes in a textbox. 
            Otherwise, show the notes as text. 
            IF you are not the sender and the sender has provided no notes, tell the user this.
            Additionally, show the "Save" button beneath the sender's notes section if you are the sender.
          */}
          <Form.Item
              name={"senderNotes"}
              label={<b>Sender's Notes:</b>}
            >
             <TextArea
              className="input-note" 
              style={{ width: "100%", height:"110px" }}
              disabled={transaction?.sender_login_email !== loginEmail} 
              /> 
            {/* {transaction?.sender_login_email === loginEmail ? (
              <TextArea
              className="input-note" 
              style={{ width: "100%", height:"110px" }}
              disabled={transaction?.sender_login_email !== loginEmail} 
              /> ) : (
                isSenderNotesAvailable ? (
                  <span>{transaction.sender_notes}</span>
                ) : (
                  <span><i>No notes have been provided by the sender yet.</i></span>
                )
              )} */}
          </Form.Item>
          {transaction?.sender_login_email === loginEmail && (
            <Form.Item
                    className="form-item form-item__submit"
                    wrapperCol={{
                      span: 24
                    }}
                >
                  <Button type="primary" htmlType="submit" disabled={isUpdating}>
                    Save Updated Notes
                  </Button>
            </Form.Item>
          )}
          
          {/* IF the recipient is currently logged in, show the notes in a textbox. 
            Otherwise, show the notes as text. 
            IF you are not the recipient and the recipient has provided no notes, tell the user this.
            Additionally, show the "Save" button beneath the recipient's notes section if you are the recipient.
          */}
          <Form.Item
              name={"recipientNotes"}
              label={<b>Recipient's Notes:</b>}
            >
            <TextArea
                className="input-note" 
                style={{ width: "100%", height:"110px" }}
                disabled={transaction?.recipient_login_email !== loginEmail}
              />
            {/* {transaction?.recipient_login_email === loginEmail ? (
              <TextArea
                className="input-note" 
                style={{ width: "100%", height:"110px" }}
                disabled={transaction?.recipient_login_email !== loginEmail}
              /> ) : (
                isRecipientNotesAvailable ? (
                  <span>{transaction.recipient_notes}</span>
                ) : (
                  <span><i>No notes have been provided by the recipient yet.</i></span>
                )
              )} */}
            </Form.Item>
            {transaction?.recipient_login_email === loginEmail && (
            <Form.Item
                    className="form-item form-item__submit"
                    wrapperCol={{
                      span: 24
                    }}
                >
                  <Button type="primary" htmlType="submit" disabled={isUpdating}>
                    Save Updated Notes
                  </Button>
            </Form.Item>
            )}
      </Form>
      <Row style={{color: '#ffffff'}}>
        <Col span={24}>

          <p> <b> Sender: </b> {transaction.sender_login_email}</p>
          <p> <b> Recipient: </b> {transaction.recipient_login_email}</p>
          <p> <b> Amount Sent: </b>{"$" + Number(transaction.txn_amount)}</p>
          <p> <b> Network Fees: </b>{"$" + transaction.blockchain_fees} </p>
          <p> <b> Total: </b> {"$" +( Number(transaction.txn_amount)+Number(transaction.blockchain_fees))}</p>
          <p> <b> Currency: </b> {transaction.currency} </p>
          <p> <b> Network: </b> {transaction.network} </p>
          <p className="text-wrap"> <b> Transaction ID (if available): </b> {transaction.network_txn_id ? transaction.network_txn_id : "not available yet"} </p>
          <p className="text-wrap"> <b> Sender's Crypto Exchange: </b> {displayExchangeName(transaction.sender_exchange)}</p>
          <p className="text-wrap"> <b> Sender's Crypto Address: </b> {transaction.sent_from}</p>
          <p className="text-wrap"> <b> Recipient's Crypto Exchange: </b> {displayExchangeName(transaction.recipient_exchange)} </p>
          <p className="text-wrap"> <b> Recipient's Crypto Address: </b> {transaction.sent_to} </p>
          <p> <b> Transaction Initated At: </b> {String(new Date(transaction.created_at))} </p>
          <p> <b> Transaction Info Updated At: </b> {String(new Date(transaction.updated_at))} </p>
          <p> <b> Transaction Status: </b> {transaction.txn_status} </p>
          <br></br>
          <p> <i> **Most transactions settle within 5 minutes. Transaction details will not be updated if the Sender pays someone and then immediately signs out of Punkypay. Transactin details will update after the sender signs into their exchange again. Additionally, you can confirm receipt of a payment manually by looking at the transactions in your cryptocurrency exchange.  </i></p>
        </Col>
      </Row>
      {/* <Form
        {...formItemLayout}
        name="leave_action_form"
        onFinish={onFinish}
        initialValues={
          {senderNotes: transaction.sender_notes, recipientNotes: transaction.recipient_notes}
        }
      > */}
        
        {/* <Row gutter={[10, 10]}>
          <Col xs={{ span: 24 }} sm={{ span: 9 }}>
            <Descriptions bordered>
            <Descriptions.Item label="Memo" span={3}>
                {transaction.memo_at_txn}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <Form.Item
              name={"senderNotes"}
              label={"Sender's Notes"}
            >
              <TextArea 
              style={{ width: "100%" }}
              disabled={transaction?.sender_login_email !== auth.currentUser?.email} 
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <Form.Item
              name={"recipientNotes"}
              label={" Recipient's Notes"}
            >
              <TextArea 
                style={{ width: "100%" }}
                disabled={transaction?.recipient_login_email !== auth.currentUser?.email}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" disabled={isUpdating}>
                Save Modifications to Notes
              </Button>
            </Col>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <Descriptions bordered>
              <Descriptions.Item label="Sender" span={10}>
                {transaction.sender_login_email}
              </Descriptions.Item>
              <Descriptions.Item label="Recipeint" span={3}>
                {transaction.recipient_login_email}
              </Descriptions.Item>
              <Descriptions.Item label="Amount Sent" span={3}>
                {"$" + Number(transaction.txn_amount)}
              </Descriptions.Item>
              <Descriptions.Item label="Blockchain Fees" span={3}>
                {"$" + transaction.blockchain_fees}
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={3}>
                {"$" +( Number(transaction.txn_amount)+Number(transaction.blockchain_fees))}
              </Descriptions.Item>
              <Descriptions.Item label="Currency" span={3}>
                {transaction.currency}
              </Descriptions.Item>
              <Descriptions.Item label="Network" span={3}>
                {transaction.network}
              </Descriptions.Item>
              <Descriptions.Item label="Sender Exchange" span={3}>
                {transaction.sender_exchange}
              </Descriptions.Item>
              <Descriptions.Item label="Recipient Exchange" span={3}>
                {transaction.recipient_exchange}
              </Descriptions.Item>
              <Descriptions.Item label="Created At" span={3}>
                {transaction.created_at}
              </Descriptions.Item>
              <Descriptions.Item label="Updated At" span={3}>
                {transaction.updated_at}
              </Descriptions.Item>
              <Descriptions.Item label="Status" span={3}>
                {transaction.txn_status}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row> */}
        {/* <Row>

        </Row>
      </Form> */}
    </div>
  );
};

export default TransactionDetails;
