import { Card } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../index.css";


const CustomCard = ({ image, title, description, route, openModal, extraLink, bgColor = "#2c2c2c" }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      if (route) {
        navigate(route); // Navigate if there's a route
      } else if (openModal) {
        openModal(); // Call the function passed in openModal
      }
    };
  
    return (
      <div onClick={handleClick} style={{ textDecoration: "none", cursor: "pointer" }}>
        <Card
          hoverable
          style={{
            borderRadius: "12px",
            backgroundColor: bgColor,
            width: 300,
            textAlign: "center",
          }}
          onClick={(e) => {
            if (e.target.tagName === "A") {
              e.stopPropagation();
            }
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt={title}
              src={image}
              style={{
                width: "50%", // Image width is 50% of the card
                borderRadius: "12px",
              }}
            />
          </div>
          <h3 style={{ color: "white", fontSize: "1.6rem" /* , marginBottom: "10px"*/ }}>{title}</h3>
          {/* <h4 style={({color: "white", fontSize: "0.8rem", marginTop: "5px" })}>Tap here to sign in</h4> */}
          <div style={{ color: "white" }}>
            {description.split("\n").map((line, index) => (
              <p key={index} style={{ margin: "5px 0" }}>
                {line}
              </p>
            ))}
          </div>
          {/* Extra link inside the card */}
          {extraLink && (
            <p>
              <a
                href={extraLink}
                onClick={(e) => e.stopPropagation()} // Prevent card click from triggering
                style={{ color: "#65aaff", textDecoration: "underline" }}
              >
                Learn More
              </a>
            </p>
          )}
        </Card>
      </div>
    );
  };

  export default CustomCard;