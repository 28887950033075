import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Space, Typography } from "antd";
import moment from 'moment';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Coinbase_Auth_Token, Coinbase_Refresh_Token } from '../const/const';
import { pad } from '../helpers/helpers';

import './styles/CryptoAccountLoginStatus.css';

// The maximum seconds it takes the Coinbase token to expire
const MAX_SECONDS = 100*60; // Should be 120 minutes (120*60 seconds) but better it expires earlier than later
const storage = localStorage;

const CryptoAccountLoginStatus = () => {
    const { Text } = Typography;
    const cryptoAccount = useSelector(state => state.cryptoAccount.account);
    const [cryptoLoginHasExpired, setCryptoLoginHasExpired] = useState(false);
    const countdownDateRef = useRef(-1); // -1 shows the timer countdown hasn't started

    // GK 2023-10-22 - gets the date difference between the time when you logged in and the current time
    const getDateDiff = (cryptoAccountData) => {
        return moment().diff(moment(cryptoAccountData.last_login), 'seconds');
    }  // GK 10:56 2024-02-16 ... I think the cryptoAccountsData is not saved by the time this runs, so we get the previous login cryptoaccount last_login, not hte most recent one

    // GK 2023-10-22 - checks to see if the coinbase login has expired
    const checkHasExpired = (cryptoAccountData) => {
        if(cryptoAccountData.exchange.toLowerCase() === 'userinput' || cryptoAccountData.exchange.toLowerCase() === 'gemini' || cryptoAccountData.exchange.toLowerCase() === 'metamask') {
            return false; // UserInput, Gemini, and Metamask don't expire
        }

        const dateDiff = getDateDiff(cryptoAccountData);
        // GK 2023-10-22 - if the detediff is greater than the maxminutes, then it is expired
        return dateDiff > MAX_SECONDS; // Token expires if dateDiff is greater than the max minutes
    }
// 2024-01-29T21:50:40.483Z
    const isUserInput = (cryptoAccountData) => {
        return cryptoAccountData.exchange.toLowerCase() === 'userinput';
    }

    const isGemini = (cryptoAccountData) => {
        return cryptoAccountData.exchange.toLowerCase() === 'gemini';
    }

    const isMetamask = (cryptoAccountData) => {
        return cryptoAccountData.exchange.toLowerCase() === 'metamask';
    }
    // GK 2024-09-10 add something for isGemini - or maybe just change to "isManual" and have the === 'userinput' OR 'gemini"?

    // GK 2023-10-22 - creates a function to remove expired tokens
    const removeCoinbaseExpiredTokens = () => {
        storage.removeItem(Coinbase_Auth_Token);
        storage.removeItem(Coinbase_Refresh_Token);
        //console.log("deleted tokens")
    }

    // GK 2023-10-22 - Sets the countdown timer
    useEffect(() => {
        if(cryptoAccount.data) {
            const hasExpired =  checkHasExpired(cryptoAccount.data);
            setCryptoLoginHasExpired(hasExpired);
            
            const dateDiff = getDateDiff(cryptoAccount.data);
            if(!hasExpired) { // set the countdown time which is current time + remaining time in milliseconds
                
                // Even if the hasExpired is false but the Coinbase auth token is not present, we expire the login
                if(!storage.getItem(Coinbase_Auth_Token)) {
                    countdownDateRef.current = 0;
                    setCryptoLoginHasExpired(true);
                    return;
                    
                }

                const loggedInRemainingSeconds = MAX_SECONDS - dateDiff;
                const remainingSecondsToMilliseconds = loggedInRemainingSeconds * 1000;
                countdownDateRef.current = Date.now() + remainingSecondsToMilliseconds;
            } else { // Token has expired
                removeCoinbaseExpiredTokens();
                countdownDateRef.current = 0; // 0 is expired
            }
        }
    }, [cryptoAccount.data])

    // react Countdown renderer callback
    // react Countdown module supplies hours, minutes, seconds, and completed
    // we then send it to the renderer below to show these messages
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Text type="danger">
                Login has expired. <Link class="light-blue" to="/crypto-login">Please sign in to an exchange!</Link>
            </Text>;
        } else {
          // Render a countdown
          return <Text>Login expires in {hours}:{pad(minutes)}:{pad(seconds)}</Text>;
        }
      };
    
    // GK 2023-10-22 - shows the crypto login countdown or display a message when login has expired
    const showCryptoLoginExpiryStatus = (cryptoAccountData) => {
        // GK 2024-09-10 - qdd something for isGemini
        if(countdownDateRef.current === -1 || isUserInput(cryptoAccountData) || isGemini(cryptoAccountData) ||  isMetamask(cryptoAccountData)) {
          return null;
        }
        
        if(countdownDateRef.current > 0) {

          return <Countdown 
                    date={countdownDateRef.current} // countdown time
                    renderer={renderer}
                    onComplete={() => {
                        removeCoinbaseExpiredTokens();
                        countdownDateRef.current = 0; // 0 is expired
                        setCryptoLoginHasExpired(true)
                    }}
                /> 
        }
    
        return <Text type="danger">
            Login has expired. <Link class="light-blue"  to="/crypto-login">Please sign in to an exchange!</Link>
        </Text>;
      }

      // Shows the last time user sign in based on an exchange
      const showCryptoLoginStatus = (cryptoAccountData) => {
        let messageType = cryptoLoginHasExpired ? 'danger' : 'success';
        let message = `You last logged in with ${cryptoAccountData.exchange} on ${
            moment(cryptoAccountData.last_login).format('LLL')
        }.`
        if(isUserInput(cryptoAccountData)) {
            messageType = 'success';
            message = `You manually entered your crypto address on ${
                moment(cryptoAccountData.last_login).format('LLL')
            }.`
        }

        if(isGemini(cryptoAccountData)) {
            messageType = 'success';
            message = `You last logged in with Gemini on ${
                moment(cryptoAccountData.last_login).format('LLL')
            }.`
        }

        if(isMetamask(cryptoAccountData)) {
            messageType = 'success';
            message = `You last logged in with Metamask on ${
                moment(cryptoAccountData.last_login).format('LLL')
            }.`
        }
        /* GK 2024-09-10 Change this to isGemini and then run a different sequence here */
        /*
        if(isUserInput(cryptoAccountData)) {
            message = `You manually entered your crypto address on ${
                moment(cryptoAccountData.last_login).format('LLL')
            }.`
        }      
        */  
        return (
            <Text type={messageType}>
                {message} 
            </Text>
        )
      }

      return (
        <Space direction="vertical">
            {
                cryptoAccount.data && showCryptoLoginStatus(cryptoAccount.data)
            }
            {
                cryptoAccount.data && showCryptoLoginExpiryStatus(cryptoAccount.data)
            }
            {
                cryptoAccount.error && (
                    <Text type="warning">
                        Thank you for signing up with Punkypay! To begin sending or receiving money, you must <Link class="light-blue" to="/crypto-login">sign in</Link> to an exchange.
                    </Text>
                )
            
            }
        </Space>
      )
}

export default CryptoAccountLoginStatus;