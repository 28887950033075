import React from 'react';
import { Button, Typography, Result } from 'antd';
import "../index.css";

const { Title, Text } = Typography;

export const ErrorScreen = ({ 
  title = "Ruh-roh...",
  message = "An error occurred. Please try clicking on the link again, or try signing out and signing back in.",
  onRetry = null,
  status = "error" // can be 'error', 'info', 'success', 'warning', '404', '403', '500'
}) => {
  return (
    <div style={{ 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: '#000000', // Black background
      color: '#FFFFFF', // White text
    }}>
      <Result
        status={status}
        title={<Title level={2} style={{ color: '#FFFFFF' }}>{title}</Title>}
        subTitle={<Text style={{ color: '#FFFFFF' }}>{message}</Text>}
        extra={[
          onRetry && (
            <Button 
              key="retry" 
              style={{ backgroundColor: '#FFFFFF', color: '#000000', border: '1px solid #FFFFFF' }}
              onClick={onRetry}
            >
              Try Again
            </Button>
          ),
          <Button 
            key="home" 
            style={{ backgroundColor: '#FFFFFF', color: '#000000', border: '1px solid #FFFFFF' }}
            onClick={() => window.location.href = '/'}
          >
            Return Home
          </Button>
        ].filter(Boolean)}
      />
    </div>
  );
};