// Serves as a wrapper to our pages, 
// this basically supplies if we are authenticated or not to the rest of the program

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { signOutUser, userStateListener, signInUser, signUpUser, sendUserVerificationEmail } from "../firebase/firebase";
import { createContext, useContext, useState, useEffect } from "react";
import {
    AUTH_TOKEN,
    Coinbase_Auth_Token,
    Coinbase_Refresh_Token,
    LAST_VISITED_LOCATION,
  } from "../const/const";
import { registerAuthorizationHeader } from "../environment";
import { getUser } from '../rest/user';
import { getCryptoAccount } from "../store/actions/cryptoAccount.action";
import { persistor } from '../store/store';

const storage = localStorage;

export const AuthContext = createContext({
  authenticating: true,
  currentUser: {},
  setCurrentUser: () => {},
  signOut: () => {},
  signIn: () => {},
  signUp: () => {}
});

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    // currentUser for MongoDB user object
    const [currentUser, setCurrentUser] = useState(null);
    // loginDetails for firebase Login response
    const [loginDetails, setLoginDetails] = useState(null); 
    const [authenticating, setAuthenticating] = useState(true);
    const [userIsVerified, setUserIsVerified] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

  // As soon as setting the current user to null, 
  // the user will be redirected to the home page. 
  const signOut = () => {
    signOutUser();
    setLoginDetails(null);
    setCurrentUser(null);
    storage.removeItem(Coinbase_Auth_Token);
    storage.removeItem(Coinbase_Refresh_Token);
    storage.removeItem(AUTH_TOKEN);
    storage.removeItem(LAST_VISITED_LOCATION);
    persistor.purge();

    if(location.pathname !== '/login' && location.pathname !== '/register') {
        navigate('/');
    }
  }

  // GK 2023-10-22 - what happens when you sign in basically - you get the user, get the crypto account info
  const fetchUser = async () => {
    try {
      const response = await getUser();
      dispatch(getCryptoAccount());
      
      const lastVisitedLocation = storage.getItem(LAST_VISITED_LOCATION);
      const redirectUrl = lastVisitedLocation ? lastVisitedLocation : '/transactions';
      console.log('Mongo User ', response);
      setCurrentUser(response.data);
      
      navigate(redirectUrl);
      console.log('Fetched User', response);
    } catch (error) {
      console.log('FetchUser Error =>', error);


      // GK 2025-02-12 - This is what I'm adding to help deal with tokens expiring
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data.errorCode === 'TOKEN_EXPIRED') {
        signOut();
      }
    }
  }

  // redirect AFTER current user is set
  useEffect(() => {
    if(!loginDetails) {
        return;
    }

    // if the user has not verified via email, the user should be logged out
    if(!loginDetails?.emailVerified) {
        signOut();
        return;
    }

    // if the user is logged in, then we should run the fetchUser() function
    if(loginDetails && registerAuthorizationHeader(loginDetails.accessToken)) {
        fetchUser();       
    }
  }, [loginDetails]);


  useEffect(() => {
    //Subscribe to firebase authentication events like login , logout etc
    const unsubscribe = userStateListener((loginResponse) => {
      if (loginResponse) {
        storage.setItem(AUTH_TOKEN, loginResponse.accessToken);        
        setUserIsVerified(loginResponse.emailVerified);
        setLoginDetails(loginResponse);
        
      } else {
        setLoginDetails(null);
      }
      setAuthenticating(false);
    });

  // GK 2023-10-22 - Any time the auth context is destroyed, unsubscribe from the userStateListener event
  return unsubscribe;
  }, [setLoginDetails]);

  const value = {
    authenticating,
    loginDetails, // Login details from Firebase,
    setLoginDetails,
    currentUser, // User object from Mongo Backeend
    setCurrentUser,
    userIsVerified, // Indicates if user has complete email verification or not
    sendUserVerificationEmail,
    signOut,
    signIn: signInUser,
    signUp: signUpUser,
  }

  // GK 2023-10-22 - provides the context for authentication
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Custom hook to access our firebase auth information
export const useAuth = () => useContext(AuthContext);