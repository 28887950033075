import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import MetaMaskConnection from "./MetaMaskConnection";

import './styles/Home.css';
import thaiBoats from '../images/thai-boats-1.jpg';
import smugCoins from '../images/smug-gold-coin-arabs.jpg';
import baroquePortrait from '../images/baroque-woman-bitcoin-portrait.jpg';
import walletOfFuture from '../images/wallet-of-future.jpg';
import podcastersImage from '../images/podcasters.jpeg';
import theFutureIsBright from '../images/the-future-is-bright.jpeg';
import helpingAtComputer from '../images/helping-at-computer.jpg';

const Home = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(auth.loginDetails) {
          navigate('/transactions')
        }
      }, []);

    return (
        <div>
            <Row justify="center">
                <Col>
                    
                    {/* Intro */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> International Payments. Way cheaper. Way Faster. </h2>          
                            <p className="auth-title-text">
                                International transactions on Punkypay settle for under 0.5%. 
                                This is way cheaper than international wire transfers which settle for 4%, and international credit card transactions which settle for about 7%.  
                            </p>
                            <p className="auth-title-text">
                                All transactions on Punkypay settle in about 3 minutes. International bank wires settle in about 2 days and international credit card transactions settle in about 5 days. 
                                Most importantly, Punkypay will never hold your money in limbo. 
                            </p>
                            <p className="auth-title-text">
                                If you want to see how much Punkypay will save you, <Link class="light-blue" to="/cost-breakdown">click here </Link>and contact <a class="light-blue" href="mailto:sales@punkypay.com">sales@punkypay.com</a> 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={thaiBoats} alt="thaiBoats"/>
                        </div>
                    </div> {/* end container */}



                    {/* useful not theoretical */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Useful, not theoretical </h2>
                                    
                            <p className="auth-title-text">
                            With Punkypay, you use the US Dollar in cryptocurrency format. 
                            This combines the speed and cost savings of cryptocurrencies with the strength of the US Dollar. 
                            </p>

                            <p className ="auth-title-text">
                            Punkypay is like a credit card network, but rather than networking together banks, Punkypay networks together crypto exchanges. 
                            </p>

                            {/* <p className="auth-title-text">
                                By using the US Dollar in crypto format, you can get your money to your destination 99% faster than any other 
                                payment method for about 95% less. 
                            </p>
                            <p className="auth-title-text">
                                Each transaction settles in about 3 minutes and costs 1% of the total amount sent. 
                                Once your money reaches its destination, the recipient can easily convert their US Dollars into local currency.
                            </p> */}
                            {/* <p className="auth-title-text">
                                <i> As our introductory offer, we aren't charging these fees. Contact <a class="light-blue" href="mailto:sales@punkypay.com">sales@punkypay.com</a> for more information. </i>
                            </p> */}
                        </div>
                        <div className="image-container">
                            <img src={smugCoins} alt="smugCoins"/>
                        </div>
                    </div> {/* end container */}



                    {/* intutiive intercace  */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Intuitive Interface </h2>
                                    
                            <p className="auth-title-text">
                                Cryptocurrencies have been plagued by poor user interface since their inception. 
                            </p>
                            <p className="auth-title-text">
                                Punkypay makes paying with crypto easy. 
                                Instead of sending money to someone’s hexidecimal crypto address, you simply send money to their email.  
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={helpingAtComputer} alt="helping-at-computer"/>
                        </div>
                    </div> {/* end container */}

                    {/* upgrade your wallet  */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Upgrade your wallet. Upgrade your life. </h2>
                                    
                            <p className="auth-title-text">
                                There are so many use cases for Punkypay that we can't mention every one of them.
                            </p>
                            <p className="auth-title-text">
                                How will Punkypay <Link class="light-blue" to="/use-cases">help you</Link>?
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={walletOfFuture} alt="walletOfFuture"/>
                        </div>
                    </div> {/* end container */}
                    {/* 
                        Upgrade your business. 
                        There are so many use cases for Punkypay that we can't mention every one of them.
                        How will Punkypay help your business?
                        MAYBE THROW IN A LINK TO SALES@PUNKYPAY.COM, AFTER I START SELLING THIS
                    */}
{/* 
                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Wholesale Distribution </h2>
                            <p className="auth-title-text">
                                Wholesalers who import products in bulk can optimize their international transactions by using PunkyPay. This results in cost savings up to 10%, and drastically improves your company’s bottom line. 
                            </p>

                            <p className="auth-title-text">
                                Let’s imagine your company buys textiles from abroad and sells them domestically. 
                                If your company buys $1,000,000 of textiles and you have to pay 3% in fees to make this transaction, your company will spend $30,000. 
                                If your company used Punkypay to make the transaction, we would charge just 1%, or $10,000.  
                                This saves your company $20,000 that goes straight into your profit margin, no effort needed.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={wholesaleDistributionCenter} alt="wholesaleDistributionCenter"/>
                        </div>
                    </div> 

                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Paying Offshore Workers </h2>
                            <p className="auth-title-text">
                                International wire transfers and other traditional payment methods often come with hefty fees, unfavorable exchange rates, and take days to complete. 
                                Punkypay offers significantly lower transaction costs, much better exchange rates, and nearly instant payments. 
                                This means more of the funds you intend to send abroad actually reach your workers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={offshoreWorkersNigeria} alt="offshore-workers-nigeria"/>
                        </div>
                    </div> 

                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> E-Commerce Business Expansion </h2>
                            <p className="auth-title-text">
                                Suppose you run an e-commerce store and want to expand your product line by sourcing unique items from international suppliers. 
                                You can use Punkypay to pay your overseas suppliers quickly, securely, and at a fraction of the cost compared to traditional bank transfers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={mailpersonWithPackage} alt="mailpersonWithPackage"/>
                        </div>
                    </div> 


                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Food and Beverage Import </h2>
                            <p className="auth-title-text">
                                Businesses that import specialty food and beverages from abroad can benefit from the speed and cost savings of Punkypay, ensuring a more competitive pricing structure.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={foodAndBev} alt="food-and-beverage-import"/>
                        </div>
                    </div> 


                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Manufacturing Components </h2>
                            <p className="auth-title-text">
                                If you're a manufacturer, you may need to import specific components or raw materials from overseas to keep your production running smoothly. 
                                Punkypay ensures that payments are processed rapidly and affordably, allowing you to maintain a competitive edge.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={warehouse} alt="warehouse"/>
                        </div>
                    </div> 




                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Dropshipping </h2>
                            <p className="auth-title-text">
                                If you're involved in dropshipping, where you source products from international suppliers and sell them online without holding inventory, Punkypay can facilitate faster and more cost-effective payments to your suppliers.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={shippingJapanBeautiful} alt="shipping-japan-beautiful"/>
                        </div>
                    </div> 



                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Currency Conversion </h2>
                            <p className="auth-title-text">
                                If you are paying people in different countries with various currencies, the Punkypay network always provides the most competitive currency conversion rates. This results in significant cost savings for the merchant and customer.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={spendingInNepal} alt="spending-in-nepal"/>
                        </div>
                    </div> 


                   
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Simplified Record Keeping </h2>
                            <p className="auth-title-text">
                                Merchants and Customers can attach notes and receipts to each transaction, helping both parties keep clear records of the purpose of payments and facilitating financial record-keeping for tax or accounting purposes.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={recordKeeping} alt="record-keeping"/>
                        </div>
                    </div> 


                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Hotel and Tour Payments </h2>
                            <p className="auth-title-text">
                                Travelers can use Punkypay to book and pay for hotels, tours, and activities at their destination. 
                                This eliminates the need to carry large amounts of cash or rely on alternative payment methods, streamlining the booking process.
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={hawaiiBeach} alt="hawaii-beach"/>
                        </div>
                    </div> 


                    
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Why we created Punkypay </h2>
                            <p className="auth-title-text"> 
                                Punkypay was dreamed up by Gavin Kerr. 
                                Gavin has both conducted business abroad and lived overseas, so he understands the complexity, the cost, and the slowness of making international transactions. 
                                Gavin has worked as a Data Scientist at TD Bank, focusing on compliance. 
                                Gavin has volunteered his time to build Punkypay alongside numerous other programmers - hailing from Japan, India, Nigeria, and Indonesia. 
                            </p>

                            <p className="auth-title-text">
                                By making payments affordable, easy, and fast we know that we can help bridge borders, making the world a better place. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={japaneseTownCartoon} alt="japanese-town-cartoon"/>
                        </div>
                    </div> 
 */}

                    {/* The future is bright */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> The Future is Bright </h2>
                            <p className="auth-title-text"> 
                                Punkypay is awesome right now, but it's getting better. 
                            </p>

                            <p className="auth-title-text">
                                Check out our <Link class="light-blue" to="/roadmap">roadmap into the future</Link>. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={theFutureIsBright} alt="the-future-is-bright"/>
                        </div>
                    </div> {/* end container */}


                    {/* Podcast */}
                    <div className="container">
                        <div className="text-container">
                            <h2 className="auth-title-text"> Introducing the Punkypay Podcast </h2>
                            <p className="auth-title-text"> 
                                Punkypay's long-form podcast interviews explorers, entrepeneurs, and engineers building our future. 
                                Unlike other podcasts, we have first-hand experience building products and bringing them to market - so we can truly empathise with our fellow adventurers.
                            </p>

                            <p className="auth-title-text">
                            Find <Link class="light-blue" to="/podcast">more info</Link> about our podcast here. 
                            </p>
                        </div>
                        <div className="image-container">
                            <img src={podcastersImage} alt="podcasters-image"/>
                        </div>
                    </div> {/* end container */}

                    {/* <div className="App flex flex-col justify-center items-center h-screen">
                        <h1 className="text-2xl font-bold mb-4">Test MetaMask Connection</h1>
                        <MetaMaskConnection />
                    </div> */}
                </Col>
            </Row>
        </div>
        
    )
}

export default Home;



// I think I can delete below but want to double check before I do:

/* 

                    <h2 className="auth-title-text"> <b> International Payments. Way cheaper. Way faster. </b> </h2>
                    
                    <h1 className="auth-title-text">
                        Tired of international transaction fees eating to your profit margin? 
                        Punkypay will radically improve your bottom line. 
                        International transactions on Punkypay settle for 1%. This is way cheaper than international wire transfers which settle for over 3%, and international credit card transactions which settle for about 7%. 
                        
                    </h1>
                    <h1 className="auth-title-text">
                        All transactions settle in about 3 minutes. International bank wires settle in about 2 days and international credit card transactions settle in about 5 days. Most importantly, Punkypay will never hold your money in limbo. 
                    </h1>
                    <h1 className="auth-title-text">
                        If you want to see how much Punkypay will save you, <Link to="/how-much-punkypay-saves-you">click here. </Link>
                    </h1>
                    <p className="auth-title-text">
                        <i> EARLY BIRD SPECIAL: Punkypay is charging no transaction fees until further notice. </i>
                    </p>


                    <div className="img-home-wrapper">
                        <img className="img-home" src={img} alt="Home Image"/>
                    </div>

                    <h2 className="auth-title-text"> <b> Useful, not Theoretical </b> </h2>
                    <h1 className="auth-title-text">
                        With Punkypay, you use the US Dollar in cryptocurrency format. 
                        By using the US Dollar in crypto format, you can get your money to your destination 99% faster than any other 
                        payment method for about 85% less. Each transaction settles in about 3 minutes and costs 1% of the total amount sent. 
                        Once your money reaches its destination, the recipient can easily convert their US Dollars into local currency. 
                    </h1>
                    <p className="auth-title-text">
                        <i> EARLY BIRD SPECIAL: Punkypay is charging no transaction fees until further notice </i>
                    </p>


                    <div className="img-home-wrapper">
                        <img className="img-home" src={midevalCoinExchange} alt="wildWestManWithHammer"/>
                    </div>

                    <h2 className="auth-title-text"> <b>  Intuitive interface </b> </h2>
                    <h1 className="auth-title-text">
                        Cryptocurrencies have been plagued by poor user interface since their inception. 
                        Punkypay makes paying with crypto easy. 
                        Instead of sending money to someone’s hexidecimal crypto address, you simply send money to their email. 
                    </h1>
                    

                    <div className="img-home-wrapper">
                        <img className="img-home" src={baroqueWomanWithBitcoin} alt="wildWestManWithHammer"/>
                    </div>

                    <h2 className="auth-title-text"> <b> Punkypay in Action </b> </h2>
                    <h3 className="auth-title-text"> Wholesale Distribution </h3>
                    <p className="auth-title-text">
                        Wholesalers who import products in bulk can optimize their international transactions by using PunkyPay. This results in cost savings up to 10%, and drastically improves your company’s bottom line. 
                    </p>

                    <p className="auth-title-text">
                        Let’s imagine your company buys textiles from abroad and sells them domestically. 
                        If your company buys $1,000,000 of textiles and you have to pay 3% in fees to make this transaction, your company will spend $30,000. 
                        If your company used Punkypay to make the transaction, we would charge just 1%, or $10,000.  
                        This saves your company $20,000 that goes straight into your profit margin, no effort needed.
                    </p>

                    <h3 className="auth-title-text"> E-Commerce Business Expansion </h3>
                    <p className="auth-title-text">
                        Suppose you run an e-commerce store and want to expand your product line by sourcing unique items from international suppliers. 
                        You can use PunkyPay to pay your overseas suppliers quickly, securely, and at a fraction of the cost compared to traditional bank transfers.
                    </p>

                    <h3 className="auth-title-text"> Manufacturing Components </h3>
                    <p className="auth-title-text">
                        If you're a manufacturer, you may need to import specific components or raw materials from overseas to keep your production running smoothly. 
                        PunkyPay ensures that payments are processed rapidly and affordably, allowing you to maintain a competitive edge.
                    </p>

                    <h3 className="auth-title-text"> Food and Beverage Import </h3>
                    <p className="auth-title-text"> 
                        Businesses that import specialty food and beverages from abroad can benefit from the speed and cost savings of PunkyPay, ensuring a more competitive pricing structure.
                    </p>

                    <h3 className="auth-title-text"> Paying Offshore Workers </h3>
                    <p className="auth-title-text">
                        International wire transfers and other traditional payment methods often come with hefty fees, unfavorable exchange rates, and take days to complete. 
                        PunkyPay offers significantly lower transaction costs, much better exchange rates, and nearly instant payments. 
                        This means more of the funds you intend to send abroad actually reach your workers.
                    </p>
                    
                    <h3 className="auth-title-text"> Dropshipping </h3>
                    <p className="auth-title-text">
                    If you're involved in dropshipping, where you source products from international suppliers and sell them online without holding inventory, PunkyPay can facilitate faster and more cost-effective payments to your suppliers.
                    </p>

                    <h3 className="auth-title-text"> Simplified Record Keeping </h3>
                    <p className="auth-title-text">
                        Merchants and Customers can attach notes and receipts to each transaction, helping both parties keep clear records of the purpose of payments and facilitating financial record-keeping for tax or accounting purposes.
                    </p>

                    <h3 className="auth-title-text"> Currency Conversion </h3>
                    <p className="auth-title-text">
                        If you are paying people in different countries with various currencies, the Punkypay network always provides the most competitive currency conversion rates. 
                        This results in significant cost savings for the merchant and customer.
                    </p>

                    <h3 className="auth-title-text"> Hotel and Tour Payments</h3>
                    <p className="auth-title-text"> 
                        Travelers can use PunkyPay to book and pay for hotels, tours, and activities at their destination. 
                        This eliminates the need to carry large amounts of cash or rely on alternative payment methods, streamlining the booking process.
                    </p>

                    <h2 className="auth-title-text"> Why We Created Punkypay </h2>

                    <p className="auth-title-text"> 
                        Punkypay was dreamed up by Gavin Kerr. 
                        Gavin has both conducted business abroad and lived overseas, so he understands the complexity, the cost, and the slowness of making international transactions. 
                        Gavin has worked as a Data Scientist at TD Bank, focusing on compliance. 
                        Gavin has volunteered his time to build Punkypay alongside numerous other programmers - hailing from Japan, India, Nigeria, and Indonesia. 
                    </p>

                    <p className="auth-title-text">
                        By making payments affordable, easy, and fast we know that we can help bridge borders, making the world a better place. 
                    </p>

                    <div className="img-home-wrapper">
                        <img className="img-home" src={japanBeautifulAnime} alt="japanBeautifulAnime"/>
                    </div>
*/