import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Alert, Spin, Modal, Image } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { storeCryptoAccountInMongo } from "../rest/cryptoAccount";
import { getCoinbaseUserAuth } from '../store/actions/coinbase.action';
import {
  getAccountData,
  getUserData,
  createAddress,
  getCoinbaseToken
} from "../rest/crypto-api/coinbase";
import {
  getGeminiAccessToken,
  getGeminiAddresses
} from "../rest/crypto-api/gemini";
import CryptoAccountOTPConfirmation from "./CryptoAccountOTPConfirmation";
import {
  Coinbase_Auth_Token, 
  COINBASE_REDIRECT_URI, 
  COINBASE_WWW_REDIRECT_URI,
  Gemini_Auth_Token,
  GEMINI_REDIRECT_URI,
  GEMINI_WWW_REDIRECT_URI
} from "../const/const";
import CustomCard from "./Common/CustomCard";
import { getCryptoAccount } from "../store/actions/cryptoAccount.action";
import { useAuth } from "../contexts/auth-context";
import { useAppBreakpoint } from "../hooks/breakpoints";

import coinbaseLogo from '../images/logos/coinbase-logo.png';
import geminiLogo from '../images/logos/gemini-logo.png';
import metamaskLogo from '../images/logos/metamask-logo.png';
import punkypayLogo from '../images/logos/punkypay-logo-square.png';

import './styles/Home.css';

const host = window.location.hostname.split('.')[0];
/**************************COINBASE OAUTH2 DETAILS************************************/
// for production
const redirect_uri = host === 'www' ? COINBASE_WWW_REDIRECT_URI : COINBASE_REDIRECT_URI;
// for local development
// const redirect_uri = "https://localhost:3000/crypto-login";
const client_id =
  "9975e0512b7fd2a94e7cc99e750463de52554ac5bb8b1a4b51d6f11ec6f91cdd";
const client_secret =
  "3fcf5a774c770533852e28e20a8f8d6d433ada6d5307de64006a917c6111be6c";

const COINBASE_AUTH_BASE = "https://www.coinbase.com/oauth";
const scope =
  "wallet:user:read,wallet:user:email,wallet:user:update,wallet:accounts:read,wallet:transactions:read,wallet:transactions:send,wallet:addresses:read,wallet:addresses:create,wallet:payment-methods:read,wallet:withdrawals:create,wallet:withdrawals:read,wallet:deposits:create,wallet:deposits:read,wallet:orders:create,wallet:orders:read,wallet:buys:read,wallet:sells:read";
//  "wallet:accounts:create,wallet:accounts:delete,wallet:accounts:read,wallet:accounts:update,wallet:addresses:create,wallet:addresses:read,wallet:buys:create,wallet:buys:read,wallet:checkouts:create,wallet:checkouts:read,wallet:contacts:read,wallet:deposits:create,wallet:deposits:read,wallet:notifications:read,wallet:orders:create,wallet:orders:read,wallet:orders:refund,wallet:payment-methods:delete,wallet:payment-methods:limits,wallet:payment-methods:read,wallet:sells:create,wallet:sells:read,wallet:supported-assets:read,wallet:trades:create,wallet:trades:read,wallet:transactions:read,wallet:transactions:request,wallet:transactions:send,wallet:transactions:transfer,wallet:user:email,wallet:user:read,wallet:user:update,wallet:withdrawals:create,wallet:withdrawals:read"

  const coinbaseState = "134ef5504a94";
// user can enter real amount they want to send, will need to prompt them on this
const PARAM = `response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${coinbaseState}&scope=${scope}&account=select&account_currency=USDC&meta[send_limit_amount]=1.00&meta[send_limit_period]=day`;
const COINBASE_AUTH_URL = `${COINBASE_AUTH_BASE}/authorize?${PARAM}`;
/**************************COINBASE OAUTH2 DETAILS************************************/

/**************************GEMINI OAUTH2 DETAILS************************************/
// for production
const geminiRedirectUri = host === 'www' ? GEMINI_WWW_REDIRECT_URI : GEMINI_REDIRECT_URI;
// for local development
// const redirect_uri = "https://localhost:3000/crypto-login";
const geminiClientId =
  "66afb5f7-2984-4a68-a948-9c0dd786c27b";
const geminiClientSecret =
  "66afb5f7-2151-402a-a73f-ce94a8fc4678";

const GEMINI_AUTH_BASE = "https://exchange.gemini.com";
const geminiScope =
  "addresses:read, history:read, account:read, orders:read, payments:read, balances:read, banks:read, clearing:read";

  const geminiState = "134e4253yetd67";
// user can enter real amount they want to send, will need to prompt them on this
const GEMINI_PARAM = `response_type=code&client_id=${geminiClientId}&redirect_uri=${geminiRedirectUri}&state=${geminiState}&scope=${geminiScope}`;
const GEMINI_AUTH_URL = `${GEMINI_AUTH_BASE}/auth?${GEMINI_PARAM}`;
/**************************COINBASE OAUTH2 DETAILS************************************/
// GET https://exchange.gemini.com/auth?client_id=my_id&response_type=code&redirect_uri=www.example.com/redirect&state=82350325&scope=balances:read,orders:create
// GK 2023-10-22 - logs in to Coinbase or Gemini, etc. 
const CryptoLogin = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [coinbaseUser, setCoinbaseUser] = useState(null);
  const [coinbaseWallet, setCoinbaseWallet] = useState(null);
  const [coinbaseLoading, setCoinbaseLoading] = useState(false);
  const [geminiLoading, setGeminiLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpenCoinbaseModal, setIsOpenCoinbaseModal] = useState(false);
  const [isOpenGeminiModal, setIsOpenGeminiModal] = useState(false);
  const [isOpenManualAddressModal, setIsOpenManualAddressModal] = useState(false);
  const [isOpenMetamaskModal, setIsOpenMetamaskModal] = useState(false);

  const [showOtpField, setShowOtpField] = useState(false);
  
  const storage = localStorage;
  const urlParams = new URLSearchParams(window.location.search);
  const [code, setCode] = useState(urlParams.get("code"));
  const [authState, setAuthState] = useState(urlParams.get("state"));
  const loginEmail = auth.currentUser?.email;

  const [coinbaseAuthToken, setCoinbaseAuthToken] = useState();
  const [geminiAuthToken, setGeminiAuthToken] = useState();
  const dispatch = useDispatch();
  const coinbaseLoginButtonRef = useRef(); // A ref for actual Coinbase button link
  const geminiLoginButtonRef = useRef(); // A ref for actual Gemini button link
  const isMediumScreen = useAppBreakpoint('md');

  // TODO: remove this after your testing
  // useEffect(() => {
  //   console.log('NAVIGATE')
  //   navigate("/transactions");
  // }, []);

  const handleModalOk = (exchange) => {
    if(exchange === 'coinbase') {
      setIsOpenCoinbaseModal(false);
      coinbaseLoginButtonRef.current?.click();
    }
    if(exchange === 'gemini') {
      setIsOpenGeminiModal(false);
      geminiLoginButtonRef.current?.click();
    }
  }

  const storeGeminiAccount = async (accessToken) => {
    try {
      const network = 'ethereum';
      const response = await getGeminiAddresses(accessToken, network);
      const address = response[0]?.address;
      if(address) {

        const user = {
          id: address
        }        
        storeCryptoAccountInMongo(
          address,
          'gemini',
          user,
        ).then((res) => {
          if(res.status === 201 || res.status === 200) {
            setError(null);
            // Refetch the user crypto-account info
            dispatch(getCryptoAccount()).then(() => {
              navigate("/transactions"); // Navigate to the transactions page after dispatch is successful
            })
          }
        })
        .catch((err) => {
          let errorMessage = 'Something went wrong logging in or saving the Gemini account. Try again!';
          // If the error response indicates an expired token, sign out the user
          if (err.response && err.response.data?.errorCode === 'TOKEN_EXPIRED') {
            auth.signOut();
          }
          if(err?.response?.data?.errorCode === 'VERIFY_OTP') {
            errorMessage = `OTP (one time password) sent to ${loginEmail}. Don't forget to check your spam!`;
            setShowOtpField(true);
          }
    
          setError(errorMessage);
          setGeminiLoading(false);
        });
      }
    } catch (error) {
      setError('Something went wrong getting your access token from Gemini. Please try again!');
      setGeminiLoading(false);
    }
  }

  // GK 2023-10-22 - this is the script to save Coinbase data into the CryptoAccount databse
  const storeCoinbaseAccount = (address) => {
    if(!loginEmail || !coinbaseUser || !coinbaseWallet || !address) {
      return;
    }
    // GK 2023-11-06 we shoudl edit the above to give an error message if it fails for any reason
    
    const user = {
      id: coinbaseUser?.id,
      email: coinbaseUser?.email,
      exchange_status: coinbaseUser?.tiers?.completed_desciption,
    }

    const wallet = {
      balance: coinbaseWallet?.balance?.amount,
      id: coinbaseWallet.id 
    }
    
    storeCryptoAccountInMongo(
      address,
      'coinbase',
      user,
      wallet,
    ).then((res) => {
      if(res.status === 201 || res.status === 200) {
        setError(null);
        // Refetch the user crypto-account info
        dispatch(getCryptoAccount()).then(() => {
          navigate("/transactions"); // Navigate to the transactions page after dispatch is successful
        })
      }
    })
    .catch((err) => {
      let errorMessage = 'Something went wrong logging in or saving the Coinbase account. Try again!';
      // If the error response indicates an expired token, sign out the user
      if (err.response && err.response.data?.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      
      if(err?.response?.data?.errorCode === 'VERIFY_OTP') {
        errorMessage = `OTP (one time password) sent to ${loginEmail}. Don't forget to check your spam!`;
        setShowOtpField(true);
      }

      setError(errorMessage);
      setCoinbaseLoading(false);
    });
  }

  const createCoinbaseAddress = async (coinbaseToken, coinbaseWalletId, data) => {
    try {
      const res = await createAddress(coinbaseToken, coinbaseWalletId, data);
      console.log('createAddress ===>', res);
      if(res?.address) {
        storeCoinbaseAccount(res.address);
      }
    } catch (error) {
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data?.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      if(error.response?.data?.errors[0]?.id === 'invalid_request') {
        const errorMessage = <div>
          Something went wrong when signing you into Coinbase. Either you lost connection, or more likely you do not have a Level 3 account.&nbsp;
           <a class="light-blue" href="https://help.coinbase.com/en/coinbase/trading-and-funding/buying-selling-or-converting-crypto/how-do-i-raise-my-limits" target="_blank">
            Click here to see a video on how to get a level3 account.
          </a>
        </div>
        setError(errorMessage);
      } else {
        setError('You were unable to sign in. Please try signing-in again.')
      }
      setCoinbaseLoading(false);
    }
  }

  // useEffect(() => {
  //   let authToken = storage.getItem(AUTH_TOKEN);

  //   if (!authToken) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => { // Step 3
    if(coinbaseAuthToken && coinbaseUser && coinbaseWallet) {
      console.log('Coinbase User =>', coinbaseUser);
      console.log('Coinbase Wallet ==>', coinbaseWallet);

    //  const data = {
    //   name: 'Punkypay Solana USDC Address',
    //   network: 'solana'
    // }
    // GK June 2024 : 
    // Now, we should have one for Ethereun
    const data = {
      name: 'Punkypay Ethereum USDC Address',
      network: 'ethereum'
    }

    // GK 2023-10-22 - creates Solana USDC address, 
    // GK June 2024 - We will want to update this to get an ETHUSDC address
    // we create the address rather than get it from addresses because this is the only way we can consistently retrieve this address
    createCoinbaseAddress(coinbaseAuthToken, coinbaseWallet.id, data);
    }
   }, [coinbaseAuthToken, coinbaseUser, coinbaseWallet]);

  // GK 2023-10-22 - gets the Coinbase Access Token for coinbase api requests
  const getCoinbaseTokenFromCode = async (code) => {
    try {
      const response = await getCoinbaseToken(code, redirect_uri);

      storage.setItem(Coinbase_Auth_Token, response.data.access_token);
      setCoinbaseAuthToken(response.data.access_token);
    } catch (error) {
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data?.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      setError('Something went wrong getting your access token from Coinbase. Please try again!');
      setCoinbaseLoading(false);
    }
  };

  const getGeminiTokenFromCode = async (code) => {
    try {
      const response = await getGeminiAccessToken(code, redirect_uri);
      storage.setItem(Gemini_Auth_Token, response.data.access_token);
      setGeminiAuthToken(response.data.access_token);
    } catch (error) {
      // If the error response indicates an expired token, sign out the user
      if (error.response && error.response.data?.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }
      setError('Something went wrong getting your access token from Gemini. Please try again!');
      setGeminiLoading(false);
    }
  }

  useEffect(() => { // Step 1
    console.log('Auth State =>', authState);
    if (code && authState === coinbaseState && !coinbaseAuthToken) {
      setCoinbaseLoading(true);
      getCoinbaseTokenFromCode(code);
    }
    if (code && authState === geminiState && !geminiAuthToken) {
      setGeminiLoading(true);
      getGeminiTokenFromCode(code);
    }
  }, [code, authState]); // GK 2023-10-22 - run this if the code changes

  
  // useEffect(() => { // Step 2 OLD SECTION - Was breaking due to getUserData returning nothing sometimes
  //   if (coinbaseAuthToken) {
  //     if (coinbaseUser === null) {
  //       getUserData(coinbaseAuthToken).then((res) => {
  //         console.log('getUserData =>>>>>>>', res);
  //         setCoinbaseUser(res);
  //       });
  //     }
  //     if (coinbaseWallet === null) {
  //       getAccountData(coinbaseAuthToken, 'USDC')
  //         .then((res) =>{
  //           console.log('getAccountData (CoinBase Wallets) =>>>>', res);
  //           const coinBaseWalletAccount = res;
          
  //           if(coinBaseWalletAccount) {
  //             if(coinBaseWalletAccount.balance.currency !== 'USDC') {
  //               setError(
  //                 `There was a server side issue authenticating with Coinbase.
  //                 We expected to see your USD Coin wallet but got a different one. Please try logging in again.`
  //               );
  //               setCoinbaseLoading(false);
  //               return;
  //             }
  //             setCoinbaseWallet(coinBaseWalletAccount);
  //           }
  //       })
  //       .catch((err) => {
  //         let errorMessage = err?.message ?? 'Something went wrong. Try again!';
  //         if(err.code === 'ERR_NETWORK') {
  //           errorMessage = 'Coinbase seems not to be responding. Try again later!';
  //         }
  //         if(err.response?.data?.errorCode) {
  //           errorMessage = err.response.data.message;
  //         }
  //         setError(errorMessage);
  //         setCoinbaseLoading(false);
  //       })
  //     }

  //     // dispatch(getCoinbaseUserAuth(coinbaseAuthToken)); this was commented out because this call now fails due to user/aith being depricated by coinbase
  //   }
  // }, [coinbaseAuthToken]);
  
  // This new code retries the getUserData and getAccountData function 10 times and displays an error if it doesnt succeed
  // START NEW CODE
  async function fetchWithRetries(fetchFunction, args = [], maxRetries = 10, delay = 1000) {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const result = await fetchFunction(...args);
        if (result) {
          return result;
        }
        console.log(`Attempt ${retries + 1} returned no result. Retrying...`);
      } catch (error) {
        console.error(`Attempt ${retries + 1} failed:`, error);
      }
      retries++;
      await new Promise((resolve) => setTimeout(resolve, delay)); // Optional delay between retries
    }
    throw new Error(`Failed to fetch data after ${maxRetries} attempts.`);
  }
  
  useEffect(() => { // Step 2
    if (coinbaseAuthToken) {
      if (coinbaseUser === null) {
        fetchWithRetries(getUserData, [coinbaseAuthToken])
          .then((res) => {
            console.log('getUserData =>>>>>>>', res);
            setCoinbaseUser(res);
          })
          .catch((error) => {
          // If the error response indicates an expired token, sign out the user
          if (error.response && error.response.data?.errorCode === 'TOKEN_EXPIRED') {
            auth.signOut();
          }
            console.error('Failed to fetch Coinbase user data:', error);
            setError('Failed to fetch Coinbase user data after multiple attempts. Try again!');
            setCoinbaseLoading(false);
          });
      }
  
      if (coinbaseWallet === null) {
        fetchWithRetries(getAccountData, [coinbaseAuthToken, 'USDC'])
          .then((res) => {
            console.log('getAccountData (CoinBase Wallets) =>>>>', res);
            if (res?.balance?.currency !== 'USDC') {
              setError(
                `There was a server-side issue authenticating with Coinbase.
                We expected to see your USD Coin wallet but got a different one. Please try logging in again.`
              );
              setCoinbaseLoading(false);
              return;
            }
            setCoinbaseWallet(res);
          })
          .catch((error) => {
          // If the error response indicates an expired token, sign out the user
          if (error.response && error.response.data?.errorCode === 'TOKEN_EXPIRED') {
            auth.signOut();
          }
            console.error('Failed to fetch Coinbase wallet data:', error);
            setError('Failed to fetch Coinbase wallet data after multiple attempts. Try again!');
            setCoinbaseLoading(false);
          });
      }
    }
  }, [coinbaseAuthToken]);
  // END NEW CODE

  useEffect(() => {
    if(geminiAuthToken) {
      storeGeminiAccount(geminiAuthToken);
    }
  }, [geminiAuthToken])

  if(showOtpField) {
    return <CryptoAccountOTPConfirmation defaultError={error} />;
  }


  const openGeminiModal = () => setIsOpenGeminiModal(true);
  const openCoinbaseModal = () => setIsOpenCoinbaseModal(true);
  const openMetamaskModal = () => setIsOpenMetamaskModal(true);
  const openManualAddressModal = () => setIsOpenManualAddressModal(true);

  const cards = [
    {
      image: coinbaseLogo,
      title: "Coinbase",
      description: "Send up to $10,000 per day\nReceive any amount\nBest for USD, EUR, GBP",
      // route: "/details/1",
      openModal: openCoinbaseModal,
      // extraLink: "https://example.com",
      bgColor: "#19192e",
    },
    {
      image: geminiLogo,
      title: "Gemini",
      description: "Can only receive money\nReceive any amount\nBest for AUD, CHF, HKD, JPY, SGD",
      openModal: openGeminiModal, // Pass the function to trigger modal
      // extraLink: "https://example.com",
      bgColor: "#19282e",
    },
    {
      image: metamaskLogo,
      title: "Metamask",
      description: "Send and Receive any amount\nBest for USD, EUR",
      openModal: openMetamaskModal,
      // extraLink: "https://example.com",
      // route: "/details/2",
      bgColor: "#2e2619",
    },
    {
      image: punkypayLogo,
      title: "Punkypay Mailroom",
      description: "Receive any amount\nBest for any currency\nWorks with all wallets and exchanges",
      openModal: openManualAddressModal,
      // route: "/details/4",
      // extraLink: "https://example.com",
      bgColor: "#192e22"
    },
  ];

  return (
    <div style={{paddingTop: '40px'}}>
      {
        error && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Alert
                message={error}
                type="error"
              />
            </Col>
          </Row>
        )
      }
      {
        coinbaseLoading && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Spin spinning={coinbaseLoading} size="large">
                <Alert
                  message="Coinbase authentication in progress"
                  description="You will be redirected back to transactions page shortly."
                  type="info"
                />
              </Spin>
            </Col>
          </Row>
        )
      }
      {
        geminiLoading && (
          <Row style={{marginBottom: '10px', marginTop: '10px'}}>
            <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
              <Spin spinning={geminiLoading} size="large">
                <Alert
                  message="Gemini authentication in progress"
                  description="You will be redirected back to transactions page shortly."
                  type="info"
                />
              </Spin>
            </Col>
          </Row>
        )
      }
      {
        !coinbaseLoading && !geminiLoading && (
          <>
            <Row justify="center" gutter={[16, 14]}>
              <h2 style={{justifyContent: "center", color: "white"}}> Sign into Exchange </h2>
            </Row>
            <Row justify="center" gutter={[16, 14]}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                  gap: "20px",
                  maxWidth: "100%", // Allow grid to take full container width
                  width: "100%",
                  padding: "20px",
                  justifyContent: "center",
                }}
              >
                {cards.map((card, index) => (
                  <CustomCard key={index} {...card} />
                ))}
              </div>
            </Row>
          </>
        )
      }
      <Modal // Info Modal for Manually inputted address
        title="Notice about Punkypay Mailroom:"
        centered
        open={isOpenManualAddressModal}
        onOk={() => navigate("/manual-address")}
        okText="Proceed"
        onCancel={() => setIsOpenManualAddressModal(false)}
        width={700} // width in px
      >
        <p>1) You can only receive money in the Punkypay Mailroom, not send it.</p>
        <p>2) Ensure that you are entering an Ethereum USDC address.</p>
        <p>3) Ensure that you have tested this address works.</p>
      </Modal>
      <Modal // Info Modal for Metamask
        title="Notice about Metamask:"
        centered
        open={isOpenMetamaskModal}
        onOk={() => navigate("/metamask")}
        okText="Proceed"
        onCancel={() => setIsOpenMetamaskModal(false)}
        width={700} // width in px
      >
        <p>1) You must have installed the Metamask <Link className="light-blue" to="https://metamask.io/download/">extension</Link> on your browser if you are using a laptop or desktop.</p>
        <p>2) If you are using a mobile device, you must access Punkypay via the Metamask app which you would download from the app store.</p>
      </Modal>
      <Modal // Info Modal for Coinbase Signin
        title="Notice about Coinbase:"
        centered
        open={isOpenCoinbaseModal}
        onOk={() => handleModalOk('coinbase')}
        okText="Proceed"
        onCancel={() => setIsOpenCoinbaseModal(false)}
        width={700} // width in px
      >
        <p>
          1) Your Coinbase account must be <a class= "light-blue" href="https://help.coinbase.com/en/coinbase/getting-started/getting-started-with-coinbase/id-doc-verification" target="_blank">fully verified</a> to use with Punkypay. If you haven't fully verified your account yet, you will be prompted to after you sign into <a class="light-blue" href="https://www.coinbase.com/" target = "_blank">Coinbase</a>.
        </p>
        <p>
          2) If you are sending money, you will have login to Coinbase and ensure that you have <a class="light-blue" href="https://www.coinbase.com/how-to-buy/" target = "_blank">enough USDC</a> available to send. In the future, you will be able to do this within Punkypay.  
        </p>
        {/* <p>
          3) If you have any troubles signing in in, <a class="light-blue" href="https://help.coinbase.com/en/coinbase/getting-started/getting-started-with-coinbase/2-step-verification#authenticator-(totp)-app" target="_blank">click here</a> to view how we have gotten around unexpected issues.
        </p> */}
        <p> 
          <i> Note: Rather than reading the articles above, it may be easier to just watch <a class="light-blue" href= "https://youtu.be/WaPv0scN3-c" target="_blank">this video.</a></i>  
        </p>
      </Modal>
      <Modal // Info Modal for Gemini Signin
        title="Notice about Gemini:"
        centered
        open={isOpenGeminiModal}
        onOk={() => handleModalOk('gemini')}
        okText="Proceed"
        onCancel={() => setIsOpenGeminiModal(false)}
        width={700} // width in px
      >
        <p> 
          By signing in with Gemini, you will be able to receive money on your Gemini account but not be able to send it. 
        </p>
      </Modal>
      <Button
      // FOR COINBASE 
      // Hidden with ref since we can only trigger this from the Modal Ok button. Check "handleModalOk"
        style={{visibility: 'hidden'}} 
        type="primary" 
        ref={coinbaseLoginButtonRef} // Ref used in triggering this button link via "handleModalOk"
        disabled={coinbaseLoading} 
        block href={COINBASE_AUTH_URL}
        >
          COINBASE HIDDEN BUTTON FOR REASON ABOVE
      </Button>
      <Button
      // FOR GEMINI 
      // Hidden with ref since we can only trigger this from the Modal Ok button. Check "handleModalOk"
        style={{visibility: 'hidden'}} 
        type="primary" 
        ref={geminiLoginButtonRef} // Ref used in triggering this button link via "handleModalOk"
        disabled={geminiLoading} 
        block href={GEMINI_AUTH_URL}
        >
          GEMINI HIDDEN BUTTON FOR REASON ABOVE
      </Button>

      <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <p
            className="auth-title-text"
            style={{ textAlign: "center" }} // Center the text inside the column
          >
            Need help? Check out our{" "}
            <Link className="light-blue" to="/support">
              support page
            </Link>.
          </p>
        </Col>
      </Row>

    </div>
  );
}

export default CryptoLogin;



            {/* <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <Image 
              src={coinbaseLogo}
              alt="Coinbase Logo" 
              preview={false}
              style={{ 
                width: window.innerWidth <= 768 ? '30%' : '100%', 
                cursor: 'pointer' ,
                margin: '0 auto',
                display: 'block'
              }} // the reason this is done in-line is because the CSS isnt working as antdesign's styling is overriding the proper styling when I do the CSS
              onClick={() => setIsOpenCoinbaseModal(true)}
            />
              <Button type="primary" disabled={coinbaseLoading} block onClick={() => setIsOpenCoinbaseModal(true)}>
                Sign in with Coinbase
              </Button>
              <p class="center-text" justify="center"><i>Send and Receive Money</i></p>
              <br></br>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Image 
                src={geminiLogo}
                alt="Gemini Logo" 
                preview={false} 
                style={{ 
                  width: window.innerWidth <= 768 ? '30%' : '100%', 
                  cursor: 'pointer' ,
                  margin: '0 auto',
                  display: 'block'
                }} // the reason this is done in-line is because the CSS isnt working as antdesign's styling is overriding the proper styling when I do the CSS
                onClick={() => setIsOpenGeminiModal(true)}
              />
              <Button type="primary" disabled={geminiLoading} block onClick={() => setIsOpenGeminiModal(true)}>
                Sign in with Gemini
              </Button>
              <p class="center-text" justify="center"><i>Receive Money Only</i></p>
              <br></br>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Image 
                src={metamaskLogo}
                alt="Metamask Logo" 
                preview={false} 
                style={{ 
                  width: window.innerWidth <= 768 ? '30%' : '100%', 
                  cursor: 'pointer' ,
                  margin: '0 auto',
                  display: 'block'
                }} // the reason this is done in-line is because the CSS isnt working as antdesign's styling is overriding the proper styling when I do the CSS
                onClick={() => setIsOpenMetamaskModal(true)}
              />
              <Button type="primary"  block onClick={() => setIsOpenMetamaskModal(true)}>
                Sign in with Metamask
              </Button>
              <p class="center-text" justify="center"><i>Send and Receive Money</i></p>
              <br></br>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Image 
                src={punkypayLogo}
                alt="Punkypay Logo" 
                preview={false} 
                style={{ 
                  width: window.innerWidth <= 768 ? '30%' : '100%', 
                  cursor: 'pointer' ,
                  margin: '0 auto',
                  display: 'block'
                }} // the reason this is done in-line is because the CSS isnt working as antdesign's styling is overriding the proper styling when I do the CSS
                onClick={() => setIsOpenManualAddressModal(true)}
              />
              <Button type="primary" block onClick={() => setIsOpenManualAddressModal(true)}>
                Punkypay Mailroom
              </Button>
              <p class="center-text" justify="center"><i>Receive Money Only</i></p>
              <br></br>
            </Col>
             */}