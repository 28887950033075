import { Button, Form, Input, Alert, Divider, Row, Col } from "antd";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { storeCryptoAccountInMongo } from "../rest/cryptoAccount";
import { getCryptoAccount } from '../store/actions/cryptoAccount.action';
import { useAuth } from "../contexts/auth-context";
import CryptoAccountOTPConfirmation from "./CryptoAccountOTPConfirmation";

// GK June 2024 - This should probably be updated so we have Ethereum in here too
// const INVALID_ADDRESS = 'Invalid solana address';
// const VALID_ADDRESS = 'Valid solana address';
const INVALID_ADDRESS = 'Invalid ethereum address';
const VALID_ADDRESS = 'Valid ethereum address';

const ManualAddress = () => {
  const auth = useAuth();
  const [address, setAddress] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [addressValidity, setAddressValidity] = useState(null);
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();

  // GK 2023-10-22 - saves the manually entered crypto address if it meets the regex below
  const saveCryptoAccount = async () => {
    setAddressValidity(null);
    setError(null);

    // GK June 2024
    // regex for  Ethereum address: r"^(0x)?[0-9a-fA-F]{40}$"
    // We should make a variable so the regex changes based on a variable you input to this function
    //    ... ex. if solana, then use x regex, if ethereum, use y regex

    // Check if address is a valid Solana address
    // if(!(/^([1-9A-HJ-NP-Za-km-z]{32,44})$/.test(address))) {
    //     setAddressValidity(INVALID_ADDRESS);
    //     return;
    // }

    // ^(0x)?[0-9a-fA-F]{40}$
    // Check if address is a valid Ethereum address
    if(!(/^0x[a-fA-F0-9]{40}$/.test(address))) {
      setAddressValidity(INVALID_ADDRESS);
      return;
  }

    setInProgress(true);
    try {
      const user = { id: uuidV4()}
      await storeCryptoAccountInMongo(
        address,
        'UserInput',
        user
      );

      dispatch(getCryptoAccount());
      // sets message to valid
      setAddressValidity(VALID_ADDRESS);
      setInProgress(false)
    } catch (err) {
      let errorMessage = 'Something went wrong saving the addess. Try again';
      // If the error response indicates an expired token, sign out the user
      if (err.response && err.response.data?.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }

      // GK 2023-10-22 - we actually send the email down below, where if(showOtpField)
      if(err?.response?.data?.errorCode === 'VERIFY_OTP') {
        errorMessage = `OTP (one time password) sent to ${auth.currentUser.email}. Don't forget to check your spam!`;
        setShowOtpField(true);
      }
      
      setError(errorMessage);
      setInProgress(false)
    }
  }

  const onFinish = (value) => {
    saveCryptoAccount();
  }

  const onFinishFailed = () => {
    console.log('Finished failed');
  }

  // GK 2023-10-22 - this is actually where we send the OTP
  if(showOtpField) {
    return <CryptoAccountOTPConfirmation defaultError={error} />;
  }

  return (
    <div>
    <h2> Manual Address Form</h2>
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }}>
        <Form
        name="basic"
        labelCol={{
          // md: {offset: 8, span: 8},
          // sm: {span: 24}
        }}
        wrapperCol={{
          // md: {offset: 8, span: 8},
          // sm: {span: 24}
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        
        <Form.Item
              label="Enter Ethereum USDC Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input onChange={(e) => setAddress(e.target.value)} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            // md: {offset: 8, span: 16},
            // sm: {offset: 0, span: 24}
          }}
        >
          <Button type="primary" htmlType="submit" loading={inProgress}>
            Save
          </Button>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            // md: { offset: 6, span: 10 },
            // sm: { offset: 0, span: 24 }
          }}
        >
          {
            addressValidity === VALID_ADDRESS && (
                <Alert 
                  message={
                    <div>Address saved successfully! <Link to="/transactions">Go to Transactions</Link></div>
                  } 
                  type="success" 
                />
              )
          }
          {
            addressValidity === INVALID_ADDRESS && (<Alert message="Invalid Ethereum address provided" type="error" />)
          }
          {
            error && (<Alert message={error} type="error" />)
          }
        </Form.Item>
        </Form>
      </Col>
    </Row>
    
    
    <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <p class="auth-title-text" justify="center"> Need help? Check out our <Link class="light-blue" to="/support">support page</Link>.</p>
        </Col>
      </Row>
  </div>
  );
};

export default ManualAddress;