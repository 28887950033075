
import React, { useState, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import { useDispatch } from "react-redux";
import { useAuth } from "../contexts/auth-context";
import { getCryptoAccount } from '../store/actions/cryptoAccount.action'; 
import { storeCryptoAccountInMongo } from "../rest/cryptoAccount";
import CryptoAccountOTPConfirmation from "./CryptoAccountOTPConfirmation"; 
import { Space, Button, Typography, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { WalletOutlined } from '@ant-design/icons';

const { Text } = Typography;

const MetaMaskConnection = () => {
  const auth = useAuth();
  const [walletAddress, setWalletAddress] = useState("");
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
//   const [addressValidity, setAddressValidity] = useState(null); // I think I can remove this eventually

  const dispatch = useDispatch();

  // Check if MetaMask is installed
  const isMetaMaskInstalled = () => typeof window.ethereum !== "undefined";

  // Request to switch to Ethereum mainnet
  const switchToEthereumMainnet = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }], // Ethereum mainnet chain ID
      });
      return true;
    } catch (switchError) {
      if (switchError.code === 4902) {
        setError("Ethereum mainnet is not available in your MetaMask.");
      } else {
        setError("Failed to switch to Ethereum mainnet.");
      }
      return false;
    }
  };

  // Connect to MetaMask
  const connectToMetaMask = async () => {
    try {
      if (!isMetaMaskInstalled()) {
        setError("MetaMask is not installed. Please install MetaMask!");
        return;
      }

      setInProgress(true);

      // Check current network
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      if (chainId !== "0x1") {
        const switched = await switchToEthereumMainnet();
        if (!switched) {
          setInProgress(false);
          return;
        }
      }

      // Request account access
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (accounts.length > 0) {
        const address = accounts[0];
        setWalletAddress(address);
        setError("");

        console.log("Connected Wallet Address:", address);

        // Save Ethereum address in MongoDB
        await saveCryptoAccount(address);
      } else {
        setError("No Ethereum account found.");
      }
    } catch (err) {
      console.error("Error connecting to MetaMask:", err);
      setError("Failed to connect to MetaMask. Try again.");
    } finally {
      setInProgress(false);
    }
  };

  // Save Ethereum address in MongoDB
  const saveCryptoAccount = async (address) => {
    setError(null);
    setInProgress(true);

    try {
      // const user = { id: uuidV4() };
      const user = {
        id: address
      }    
      await storeCryptoAccountInMongo(address, "Metamask", user); 

      dispatch(getCryptoAccount());
      setInProgress(false);
    } catch (err) {
      let errorMessage = "Something went wrong saving the address. Try again.";
      // If the error response indicates an expired token, sign out the user
      if (err.response && err.response.data.errorCode === 'TOKEN_EXPIRED') {
        auth.signOut();
      }

      if (err?.response?.data?.errorCode === "VERIFY_OTP") {
        errorMessage = `OTP (one-time password) sent to ${auth.currentUser.email}. Don't forget to check your spam!`;
        setShowOtpField(true);
      }

      setError(errorMessage);
      setInProgress(false);
    }
  };

  // Detect account and network changes
  useEffect(() => {
    if (isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setError("");
        } else {
          setWalletAddress("");
          setError("MetaMask is locked or no account is connected.");
        }
      });

      window.ethereum.on("chainChanged", async (newChainId) => {
        if (newChainId !== "0x1") {
          setWalletAddress("");
          setError("Please switch to Ethereum mainnet.");
        } else {
          // Instead of reloading, try reconnecting the wallet
          try {
            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            if (accounts.length > 0) {
              setWalletAddress(accounts[0]);
              setError("");
            }
          } catch (err) {
            setError("Failed to reconnect wallet after network switch.");
          }
        }
      });
    }

    return () => {
      if (isMetaMaskInstalled()) {
        window.ethereum.removeListener("accountsChanged", () => {});
        window.ethereum.removeListener("chainChanged", () => {});
      }
    };
  }, []);

  if (showOtpField) {
    return <CryptoAccountOTPConfirmation defaultError={error} />;
  }
  return (
    <Row justify="center" style={{ paddingTop: '2rem' }}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction="vertical" align="center">
            <Button
              type="primary"
              icon={<WalletOutlined />}
              onClick={connectToMetaMask}
              loading={inProgress}
              size="large"
            >
              {inProgress ? "Connecting..." : "Connect to MetaMask"}
            </Button>
            
            {walletAddress && (
              <Text type="success">
                <div>Address saved successfully! <Link to="/transactions">Go to Transactions</Link></div>
                {/* Connected Wallet: {walletAddress} */}
              </Text>
            )}
            
            {error && (
              <Text type="danger">
                {error}
              </Text>
            )}
          </Space>
        </div>
      </Col>
    </Row>
  );

};

export default MetaMaskConnection;